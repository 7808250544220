import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import Default from './layouts/default.vue';
import Vidle from 'v-idle';
import { initI18n } from './plugins/i18n';
import Toast from 'vue-toastification';
import { createPinia, PiniaVuePlugin, setActivePinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'vue-toastification/dist/index.css';
import { localizeText } from '@/services/formatters/localize';
require('./plugins/vue-progress-bar.js');

Vue.use(Vidle);
Vue.use(Toast);
Vue.use(PiniaVuePlugin);
Vue.config.productionTip = false;
Vue.component('DefaultLayout', Default);

let app = null;
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
setActivePinia(pinia);

// TODO Vue3 add to the filter, then we can call them differently
// and it should be the same with vue3 we just change how we reg them.
// Vue.prototype.$filters = {
// 	capitalize,
// 	currency
// };

// Add them to Vue.prototype
Vue.prototype.$filters = {
	localizeText
};

initI18n().then(function (i18n) {
	app = new Vue({
		el: '#app',
		pinia,
		vuetify,
		i18n,
		router,
		render: (h) => h(App)
	});
});

export default app;
