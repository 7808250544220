import { render, staticRenderFns } from "./TabularData.vue?vue&type=template&id=3da3244e"
import script from "./TabularData.vue?vue&type=script&lang=js"
export * from "./TabularData.vue?vue&type=script&lang=js"
import style0 from "./TabularData.vue?vue&type=style&index=0&id=3da3244e&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3da3244e')) {
      api.createRecord('3da3244e', component.options)
    } else {
      api.reload('3da3244e', component.options)
    }
    module.hot.accept("./TabularData.vue?vue&type=template&id=3da3244e", function () {
      api.rerender('3da3244e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ScanningFunctions/InputComponentTypes/TabularData.vue"
export default component.exports