import i18n from '@/plugins/i18n';
import { createHtmlFormatedText } from '@/services/addTextFormatting';

/**
 * Create formating object we are going to show to the user.
 * @param {string} textString The text we are goint to format.
 * @param {string} rowData The row data with information we need to swap out.
 */
export const parseToFormatedText = function (textString) {
	if (!textString) {
		return '';
	}

	// split the text up into elements we want to format
	const elementsToTransform = textString.match(/{[^}]+}/g);
	const itemsToLocalise = [];
	const itemsToTokenize = [];

	if (!elementsToTransform) {
		return textString;
	}

	// split that up into sections that need a transformer
	elementsToTransform.forEach((element) => {
		// pull any formatter out for the string. These can be the same no matter what the type is
		var newElement = {};

		if (element.startsWith('{@') && element.endsWith('}')) {
			if (element.endsWith('@}')) {
				newElement = buildElementWithoutFormatter(element);
			} else {
				newElement = buildElementWithFormatters(element, '@');
			}

			itemsToLocalise.push(newElement);
		} else if (element.startsWith('{#') && element.endsWith('}')) {
			if (element.endsWith('#}')) {
				newElement = buildElementWithoutFormatter(element);
			} else {
				newElement = buildElementWithFormatters(element, '#');
			}

			itemsToTokenize.push(newElement);
		}
	});

	itemsToLocalise.forEach((elementToLocalize) => {
		var rawText = processLocalisationElement(elementToLocalize.key);
		elementToLocalize.outputText = rawText;
	});

	return {
		itemToLocalise: itemsToLocalise,
		itemsToTokenize: itemsToTokenize
	};
};

export const FormatTextWithFomaterOptions = function (rawText, formatters, vm) {
	formatters
		// Sort the formatters to ensure 'dataFormatter' comes first
		.sort((a, b) => {
			if (a.type === 'dataFormatter' && b.type !== 'dataFormatter')
				return -1; // Move 'dataFormatter' to the front
			if (a.type !== 'dataFormatter' && b.type === 'dataFormatter')
				return 1;
			return 0; // Keep the order unchanged for others
		})
		.forEach((formatter) => {
			switch (formatter.type) {
				case 'dataFormatter':
					rawText = ApplyDataFormatting(
						rawText,
						formatter.formatter,
						vm
					);

					break;
				case 'style':
					rawText = ApplyTextStyling(rawText, formatter.textStyle);
					break;
				default:
					console.error('Unknown formatting option', formatter.type);
			}
		});

	return rawText;
};

// Process localisation elements
export const processLocalisationElement = function (keyToLocalize) {
	return i18n.t(keyToLocalize);
};

// Create our element object that has no formatter options
export const buildElementWithoutFormatter = function (element) {
	return {
		key: element.slice(2, -2),
		tokenToSwap: element,
		outputText: null,
		formatters: []
	};
};

// Create our element object that has formatter options
export const buildElementWithFormatters = function (element, regexSeperator) {
	const regex = new RegExp(
		`\\{${regexSeperator}([^${regexSeperator}]+)${regexSeperator}\\|(.*)\\}`
	);
	const matches = element.match(regex);

	var elementName = matches[1];
	var formattingString = matches[2];

	var formatObject = CreateFormatingObjectsFromString(formattingString);

	return {
		key: elementName,
		tokenToSwap: element,
		outputText: null,
		formatters: formatObject
	};
};

/**
 * Create an object from the string formatting string data.
 * @param {string} formatString The string with the formating information
 * @returns {Array}
 */
export const CreateFormatingObjectsFromString = function (formatString) {
	const outputArray = [];
	var formatterList = formatString.split('|');

	formatterList.forEach((formatter) => {
		const formatterKeyValues = formatter.split(':');
		const formatterType = formatterKeyValues[0];

		switch (formatterType) {
			case 'format':
				var dataFormatter = CreateDataFormaterObject(
					formatterKeyValues[1]
				);
				outputArray.push(dataFormatter);
				break;
			case 'style':
				var styleFormatter = CreateStyleFormaterObject(
					formatterKeyValues[1]
				);
				outputArray.push(styleFormatter);
				break;
			default:
				console.error('Unknown formatting option', formatterType);
		}
	});

	return outputArray;
};

/**
 * Create text elements to display to the user.
 * @param {Array} convertedObjectsArray All our converted text.
 * @param {string} stringToSwapOut The text string we are swapping the values for
 */
export const swapOutCreatedTextElementToUserString = function (
	convertedObjectsArray,
	stringToSwapOut
) {
	convertedObjectsArray.forEach((element) => {
		stringToSwapOut = stringToSwapOut.replaceAll(
			element.tokenToSwap,
			element.outputText
		);
	});

	return stringToSwapOut;
};

const CreateDataFormaterObject = function (inputString) {
	return {
		type: 'dataFormatter',
		formatter: inputString
	};
};

export const CreateStyleFormaterObject = function (inputString) {
	const formatterElements = inputString.split(';');
	var textBold = false;
	var textItalic = false;
	var textColour = null;
	var textUnderline = null;
	var textFontsize = null;

	formatterElements.forEach((element) => {
		if (element.toLowerCase() === 'textbold') {
			textBold = true;
		}

		if (element.toLowerCase() === 'textitallic') {
			textItalic = true;
		}

		if (element.toLowerCase() === 'textunderline') {
			textUnderline = true;
		}

		if (element.toLowerCase().includes('textcolour')) {
			const colourArray = element.split('=');
			if (colourArray.length === 2) {
				textColour = colourArray[1].toLowerCase();
			}
		}

		if (element.toLowerCase().includes('textfontsize')) {
			const fontArray = element.split('=');
			if (fontArray.length === 2) {
				textFontsize = fontArray[1].toLowerCase();
			}
		}
	});

	return {
		type: 'style',
		textStyle: {
			bold: textBold,
			italic: textItalic,
			colour: textColour,
			underline: textUnderline,
			fontSize: textFontsize
		}
	};
};

export const ApplyDataFormatting = function (dataString, formattingOption, vm) {
	switch (formattingOption) {
		case 'date':
			dataString = vm.proxy.$d(new Date(dataString), 'short');
			break;
		case 'decimal':
			dataString = vm.proxy.$n(dataString, 'decimal');
			break;
		case 'currency':
			dataString = vm.proxy.$n(dataString, 'currency');
			break;
	}

	return dataString;
};

export const ApplyTextStyling = function (dataString, formattingOption) {
	dataString = createHtmlFormatedText(dataString, formattingOption);
	return dataString;
};
