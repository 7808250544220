import { useScannerStore } from '@/stores/scannerFunctionStore';
import { useUserStore } from '@/stores/userStore';

export const playSound = function (isError) {
	var defaultErrorSound = './sounds/error.mp3';
	const scannerStore = useScannerStore();
	const userStore = useUserStore();

	// 1 - get the current sounds for this step
	var workflowAndStep = scannerStore.getCurrentWorkflowAndStep;

	// 2 - now pass this to the user store to pull out the sounds for the step
	var workFlows = userStore.getAllowedScannerFunctions;

	// no workflow or on the restart indicator
	if (
		!workFlows ||
		!workflowAndStep?.workflowStep ||
		workflowAndStep.workflowStep == -1
	) {
		return;
	}

	var step = workFlows.find(
		(x) =>
			x.WorkFlowKey == workflowAndStep.workflowId &&
			x.SequenceNumber == workflowAndStep.workflowStep
	);

	var errorSoundToPlay = step.ApiErrorSound ?? defaultErrorSound;

	var soundToPlay = isError ? errorSoundToPlay : step.ApiSuccessSound;

	if (soundToPlay != null && soundToPlay.endsWith('.mp3')) {
		var errorAudio = new Audio(soundToPlay);
		errorAudio.play();
	}
};
