const numberFormats = {
	en: {
		currency: {
			style: 'currency',
			currency: 'USD',
			notation: 'standard'
		},
		decimal: {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}
	},
	es: {
		currency: {
			style: 'currency',
			currency: 'MXN',
			notation: 'standard'
		},
		decimal: {
			style: 'decimal',
			minimumSignificantDigits: 2,
			maximumSignificantDigits: 2
		}
	},
	fr: {
		currency: {
			style: 'currency',
			currency: 'CAD',
			notation: 'standard'
		},
		decimal: {
			style: 'decimal',
			minimumSignificantDigits: 2,
			maximumSignificantDigits: 2
		}
	}
};
export default numberFormats;
