<template>
	<v-container>
		<h1>Theme Editor</h1>

		<v-card class="mx-auto pa-2">
			<v-list nav>
				<v-list-item color="primary">
					<v-list-item-title>{{
						translate('Dark Mode', 'comp_NavBar_DarkMode')
					}}</v-list-item-title>
					<v-switch
						v-model="$vuetify.theme.dark"
						style="padding: 0"
						inset
						@change="toggleDarkMode"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Primary',
								'comp_ColorSettings_PrimaryColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.primary"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(themeUnderEdit.primary, 'primary')
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Secondary',
								'comp_ColorSettings_SecondaryColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.secondary"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(themeUnderEdit.secondary, 'secondary')
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate('Error', 'comp_ColorSettings_ErrorColor')
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.error"
						show-fallback
						swatches="text-advanced"
						:change="onChangeColor(themeUnderEdit.error, 'error')"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Information',
								'comp_ColorSettings_InfoColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.information"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(
								themeUnderEdit.information,
								'information'
							)
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Success',
								'comp_ColorSettings_SuccessColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.success"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(themeUnderEdit.success, 'success')
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Warning',
								'comp_ColorSettings_WarningColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.warning"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(themeUnderEdit.warning, 'warning')
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Background',
								'comp_ColorSettings_BackgroundColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.background"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(
								themeUnderEdit.background,
								'background'
							)
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title
						>{{
							translate(
								'Header',
								'comp_ColorSettings_HeaderColor'
							)
						}}
					</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.header"
						show-fallback
						swatches="text-advanced"
						:change="onChangeColor(themeUnderEdit.header, 'header')"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title>{{
						translate('Accent', 'comp_ColorSettings_AccentColor')
					}}</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.accent"
						show-fallback
						swatches="text-advanced"
						:change="onChangeColor(themeUnderEdit.accent, 'accent')"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title>{{
						translate('Alternate Row', 'comp_ColorSettings_AltRow')
					}}</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.alternateTableRow"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(
								themeUnderEdit.alternateTableRow,
								'alternateTableRow'
							)
						"
						popover-x="left"
					/>
				</v-list-item>
				<v-list-item color="primary" class="pa-2 bg-item">
					<v-list-item-title>{{
						translate(
							'Highlight',
							'comp_ColorSettings_HighlightColour'
						)
					}}</v-list-item-title>
					<VSwatches
						v-model="themeUnderEdit.highlight"
						show-fallback
						swatches="text-advanced"
						:change="
							onChangeColor(themeUnderEdit.highlight, 'highlight')
						"
						popover-x="left"
					/>
				</v-list-item>
			</v-list>
			<v-row class="text-center">
				<v-col cols="12">
					<v-btn color="primary" @click="resetTheme()">
						{{ translate('Restore', 'comon_Restore') }}</v-btn
					>&nbsp;&nbsp;&nbsp;
					<v-btn color="primary" @click="saveTheme()"
						>{{ translate('Save', 'comon_Save') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script setup>
import VSwatches from 'vue-swatches';
import Vue from 'vue';
import 'vue-swatches/dist/vue-swatches.css';
import { useUserStore } from '@/stores/userStore';
import { useAuthStore } from '@/stores/authStore';
import { reactive, onMounted, getCurrentInstance } from 'vue';

// create the default theme to use
var themeUnderEdit = reactive({
	primary: null,
	secondary: null,
	accent: null,
	error: null,
	informationrmation: null,
	success: null,
	warning: null,
	background: null,
	header: null,
	alternateTableRow: null,
	highlight: null,
	application: 1,
	themeType: 5
});

const userStore = useUserStore();
const authStore = useAuthStore();
const vm = getCurrentInstance();

const $toast = vm.proxy?.$toast || undefined;
const $vuetify = vm.proxy?.$vuetify || undefined;
//const $options = vm.proxy?.$options || undefined;

const onChangeColor = (newColour, key) => {
	if ($vuetify.theme.isDark) {
		$vuetify.theme.themes.dark[key] = newColour;
	} else {
		$vuetify.theme.themes.light[key] = newColour;
	}
};

const toggleDarkMode = () => {
	localStorage.setItem('dark_theme', $vuetify.theme.dark.toString());
	setThemeColours();
};

const setThemeColours = () => {
	var currentTheme = null;

	if ($vuetify.theme.isDark) {
		currentTheme = JSON.parse(localStorage.getItem('user_dark_theme'));
	} else {
		currentTheme = JSON.parse(localStorage.getItem('user_light_theme'));
	}

	if (currentTheme) {
		themeUnderEdit.primary = currentTheme.primary;
		themeUnderEdit.secondary = currentTheme.secondary;
		themeUnderEdit.accent = currentTheme.accent;
		themeUnderEdit.error = currentTheme.error;
		themeUnderEdit.information = currentTheme.info;
		themeUnderEdit.success = currentTheme.success;
		themeUnderEdit.warning = currentTheme.warning;
		themeUnderEdit.background = currentTheme.background;
		themeUnderEdit.header = currentTheme.header;
		themeUnderEdit.alternateTableRow = currentTheme.alternateTableRow;
		themeUnderEdit.highlight = currentTheme.highlight;
		themeUnderEdit.themeType = $vuetify.theme.isDark ? 6 : 5;
	}
};

const resetTheme = () => {
	var defaultTheme = null;

	if ($vuetify.theme.isDark) {
		defaultTheme = JSON.parse(localStorage.getItem('user_dark_theme'));
	} else {
		defaultTheme = JSON.parse(localStorage.getItem('user_light_theme'));
	}

	Object.keys(defaultTheme).forEach((key) => {
		themeUnderEdit[key] = defaultTheme[key];

		if ($vuetify.theme.isDark) {
			$vuetify.theme.themes.dark[key] = defaultTheme[key];
		} else {
			$vuetify.theme.themes.light[key] = defaultTheme[key];
		}
	});

	$toast.success('Theme reset', {
		timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
	});
};

const saveTheme = () => {
	userStore
		.updateOperatorTheme(themeUnderEdit, authStore.getUserId, vm.proxy)
		.then(() => {
			$toast.success('Theme saved', {
				timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
			});
		})
		.catch(() => {
			$toast.error('Issue saving theme', {
				timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
			});
		});
};

const translate = (defaultText, localizeText) => {
	return Vue.prototype.$filters.localizeText(
		defaultText,
		localizeText,
		vm.proxy
	);
};

onMounted(() => {
	setThemeColours();
});
</script>

<style scoped>
.bg-item {
	background-color: var(--v-background-base);
}
</style>
