<template>
	<v-row v-if="shouldDisplayImage" no-gutters>
		<v-img data-imageDisplay :src="getImage" />
	</v-row>
</template>

<script>
import { mapState } from 'pinia';
import { useScannerStore } from '@/stores/scannerFunctionStore';
export default {
	name: 'ImageDisplay',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	computed: {
		shouldDisplayImage() {
			return this.inputBox.Image;
		},
		getImage() {
			//find the image based on the field we pass in from the store
			var apiResponse = this.getLastApiResponse();
			var propertyToFind = this.inputBox.Image;

			//object
			if (
				typeof apiResponse === 'object' &&
				apiResponse !== null &&
				!Array.isArray(apiResponse)
			) {
				if (apiResponse[propertyToFind]) {
					return apiResponse[propertyToFind];
				}
			}

			//array
			if (Array.isArray(apiResponse)) {
				if (apiResponse[0][propertyToFind]) {
					return apiResponse[0][propertyToFind];
				}
			}

			//if we got this far we've not found it in our last request, so go and check the previous ones
			return this.getImageFromAllApiResponses(propertyToFind);
		}
	},
	methods: {
		...mapState(useScannerStore, [
			'getLastApiResponse',
			'getAllApiResponses'
		]),
		getImageFromAllApiResponses(propertyToFind) {
			var allApiResponses = this.getAllApiResponses();
			var imagePath = null;
			var foundImage = false;

			allApiResponses.forEach(function (item) {
				if (foundImage) {
					return;
				}

				//object
				if (
					typeof item === 'object' &&
					item !== null &&
					!Array.isArray(item)
				) {
					if (item[propertyToFind]) {
						imagePath = item[propertyToFind];
						foundImage = true;
					}
				}

				//array
				if (Array.isArray(item)) {
					imagePath = item[0][propertyToFind];
					if (imagePath) {
						foundImage = true;
					}
				}
			});

			return imagePath;
		}
	}
};
</script>
