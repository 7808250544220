/**
 * Format a string with html display elements.
 * @param {string} textString The text we are goint to format.
 * @param {object} formatingObject The object with the details of how to format the string.
 */
export const createHtmlFormatedText = function (textString, formatingObject) {
	if (formatingObject?.bold === true) {
		textString = `<b>${textString}</b>`;
	}

	if (formatingObject?.italic === true) {
		textString = `<i>${textString}</i>`;
	}

	if (formatingObject?.underline === true) {
		textString = `<u>${textString}</u>`;
	}

	// ok this is a bit fiddely as we have to build up the span style setting
	if (formatingObject?.colour && formatingObject?.fontSize) {
		var textColourValue = formatingObject.colour;
		var fontSizeValue = formatingObject.fontSize;
		textString = `<span style="color:${textColourValue}; font-size:${fontSizeValue}px;">${textString}</span>`;
		return textString;
	}

	if (formatingObject?.fontSize) {
		var fontSize = formatingObject.fontSize;
		textString = `<span style="font-size:${fontSize};">${textString}</span>`;
	}

	if (formatingObject?.colour) {
		var textColour = formatingObject.colour;
		textString = `<span style="color:${textColour};">${textString}</span>`;
	}

	return textString;
};
