<template>
	<Login />
</template>

<script>
import Login from '../components/Login/LoginScreen.vue';

export default {
	name: 'LoginView',

	components: {
		Login
	}
};
</script>
