<template>
	<div>
		<div v-for="p in userPrompts" :key="p.key">
			<component
				:is="getComponentName(p)"
				v-model.trim="value[p.key]"
				:input-box="p"
				@inputUpdate="onInputUpdate"
				@closeModel="closeModel"
			/>
		</div>
	</div>
</template>
<script>
import textBoxInput from './InputComponentTypes/TextBox.vue';
import labelDisplayBox from './InputComponentTypes/LabelBox.vue';
import autoCompleteInput from './InputComponentTypes/AutoCompleteBox.vue';
import tabularData from './InputComponentTypes/TabularData.vue';
import imageDisplay from './InputComponentTypes/ImageDisplay.vue';
import captureImage from './InputComponentTypes/ExternalImageCapture.vue';
import listView from './OutputComponents/ListView.vue';

export default {
	name: 'InformationComponent',
	components: {
		'component-text': textBoxInput,
		'component-label': labelDisplayBox,
		'component-autocomplete': autoCompleteInput,
		'component-tabularData': tabularData,
		'component-imageDisplay': imageDisplay,
		'component-captureImage': captureImage,
		'component-listView': listView
	},
	props: {
		userPrompts: {
			type: Array,
			default: () => [],
			required: false
		}
	},
	data: () => ({
		value: {}
	}),
	methods: {
		getComponentName(q) {
			return 'component-' + q.ComponentType;
		},
		onInputUpdate(value) {
			this.$emit('inputUpdate', value);
		},
		closeModel(value) {
			this.$emit('closeModel', value);
		}
	}
};
</script>
