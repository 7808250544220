import { render, staticRenderFns } from "./LogoutView.vue?vue&type=template&id=839c3cee"
import script from "./LogoutView.vue?vue&type=script&lang=js"
export * from "./LogoutView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('839c3cee')) {
      api.createRecord('839c3cee', component.options)
    } else {
      api.reload('839c3cee', component.options)
    }
    module.hot.accept("./LogoutView.vue?vue&type=template&id=839c3cee", function () {
      api.rerender('839c3cee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/LogoutView.vue"
export default component.exports