<template>
	<div>
		<v-container class="bg-surface-variant mb-6">
			<v-row align="center" no-gutters>
				<v-col>
					<v-sheet class="pa-2 ma-2">
						<img
							src="/img/savantLoadingM.gif"
							width="100%"
							alt="loading"
						/>
					</v-sheet>
				</v-col>
			</v-row>
			<v-row align="center" no-gutters>
				<v-col />
				<v-col> Loading... </v-col>
				<v-col />
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapActions } from 'pinia';
import { useUserStore } from '@/stores/userStore';
export default {
	name: 'LoadingScreen',
	async created() {
		// time this loading so we're active for 2000 (2 seconds)
		var actualWaitTime = 9000;
		var startTime = new Date();
		this.loadFormData();

		//lets make sure we actually wait for the required time
		var endTime = new Date();
		var dateDifference = endTime - startTime;
		var waitTime =
			dateDifference > waitTime ? 0 : actualWaitTime - dateDifference;

		setTimeout(() => {
			this.$emit('doneLoading');
		}, waitTime);
	},
	methods: {
		...mapActions(useUserStore, ['getUserSites']),
		loadFormData() {
			this.getUserSites();
		}
	}
};
</script>
