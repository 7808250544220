const filters = {
	localizeText: function (defaultText, localizeString, vm) {
		if (!localizeString && !defaultText)
			return 'Invalid text string setting';

		if (!localizeString) return defaultText;

		var localizedText = vm.$t(localizeString);

		if (localizedText !== localizeString) return localizedText;

		return defaultText;
	},

	formatDate: function (stringValue, format, vm) {
		if (!stringValue && !format) {
			return 'Invalid date value and/or format';
		}

		return vm.$d(new Date(stringValue), format);
	},

	formatNumber: function (stringValue, format, vm) {
		if (!stringValue && !format) {
			return 'Invalid number value and/or format';
		}

		return vm.$n(stringValue, format);
	}
};
export default filters;
