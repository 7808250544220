import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { useUserStore } from '@/stores/userStore';
import dateTimeFormats from '@/locales/date-time-formats';
import numberFormats from '@/locales/number-formats';
import axios from 'axios';

Vue.use(VueI18n);

//list of languages we have loaded
const loadedLanguages = [];

//load Language string from the api, can take in a language or not in case we are forcing a change
export async function loadLocaleFromApi(isoLanguage) {
	var savantLanguageCode = '';
	const store = useUserStore();

	//if we have not been passed a lang to use then load from the users default
	if (!isoLanguage || isoLanguage.length === 0) {
		savantLanguageCode = store.getLanguage;
		isoLanguage = savantCodeToIso(savantLanguageCode);
	} else {
		savantLanguageCode = isoCodeToSavant(isoLanguage);
	}

	if (loadedLanguages.includes(isoLanguage)) {
		if (i18n.locale !== isoLanguage) {
			i18n.locale = isoLanguage;
		}
		return Promise.resolve();
	}

	var basePath = process.env.VUE_APP_ROOT_API;

	return axios
		.get(
			`${basePath}/mobileapi/localisation/getalllanguagestrings/${savantLanguageCode}`
		)
		.then((res) => {
			let msgs = res.data;
			loadedLanguages.push(isoLanguage);
			i18n.locale = isoLanguage;
			i18n.setLocaleMessage(isoLanguage, msgs);
		})
		.catch(() => {
			console.log('Error loading language strings');
		});
}

//load the local code up from the store and translate savant -> iso
function savantCodeToIso(savantCode) {
	if (savantCode && savantCode.length > 0) {
		switch (savantCode.trim().toUpperCase()) {
			case 'ENGLISH':
				return 'en';
			case 'SPANISH':
				return 'es';
			case 'FRENCH':
				return 'fr';
			default:
				return 'en';
		}
	}
	return 'en';
}

//load the local code up from the store and translate savant -> iso
function isoCodeToSavant(isoCode) {
	if (isoCode && isoCode.length > 0) {
		switch (isoCode.trim().toUpperCase()) {
			case 'EN':
				return 'ENGLISH';
			case 'ES':
				return 'SPANISH';
			case 'FR':
				return 'FRENCH';
			default:
				return 'ENGLISH';
		}
	}
	return 'ENGLISH';
}

//Initialize the language
export function initI18n() {
	const store = useUserStore();
	var lang = store.getUserSetLanguage;

	return loadLocaleFromApi(lang).then(function () {
		return i18n;
	});
}

const i18n = new VueI18n({
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
	dateTimeFormats,
	numberFormats
});

export default i18n;
