import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: '#4cc2f0',
				secondary: '#ffffff',
				accent: '#676a6c',
				error: '#ed5565',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#fed46b',
				background: '#f3f3f3',
				header: '#f3f3f3',
				alternateGridColor: '#f1fbff',
				highlight: '#66DD64'
			},
			dark: {
				primary: '#1976D2',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#d51923',
				info: '#3e80ed',
				success: '#5ec232',
				warning: '#fdce3e',
				background: '#676a6c',
				header: '#263238',
				alternateGridColor: '#676a6c',
				highlight: '#D8E0BB'
			}
		}
	}
});
