var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"nav"}},[_c('v-app-bar',{staticClass:"nav-header header",attrs:{"app":"","clipped-right":_vm.clipped}},[_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"ma-4",attrs:{"src":"/img/headerSavant_new.png","width":"50","height":"45","alt":"logo"}})])],1),_c('span',[_vm._v(_vm._s(_vm.getUserSiteDescription()))]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.getIsAuthenticated),expression:"getIsAuthenticated"}],attrs:{"icon":"","data-account":""}},on),[(_vm.hasAvatarImage)?_c('v-avatar',{attrs:{"color":"primary"}},[_c('v-img',{attrs:{"src":_vm.getCurrentUserAvatarImage}})],1):_c('v-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.getUsersInitialsForBadge))])])],1)]}}])},[_c('v-card',{staticClass:"background",staticStyle:{"padding":"10px"}},[_c('v-list-item-content',{staticClass:"justify-left"},[_c('div',{staticClass:"mx-auto text-left"},[_c('h3',[_vm._v(_vm._s(_vm.getUserFullName()))]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.getUsersEmail())+" ")]),_c('v-divider'),_c('v-container',{staticStyle:{"padding":"0"},attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',[_c('v-flex',{staticClass:"justify-left",staticStyle:{"padding-left":"15px","padding-top":"0"}},[_c('v-switch',{staticStyle:{"padding":"0"},attrs:{"color":"primary","inset":"","label":_vm.translate(
												'Dark Mode',
												'comp_NavBar_DarkMode'
											)},on:{"change":_vm.toggleDarkMode},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1)],1),_c('v-flex',{staticClass:"justify-left",staticStyle:{"padding-left":"15px","padding-top":"0"}},[_c('v-switch',{staticStyle:{"padding":"0"},attrs:{"color":"primary","inset":"","label":_vm.translate(
										'Workflow Menu',
										'comp_NavBar_WorkFlowMenu'
									)},on:{"change":_vm.toggleWorkflowMenu}})],1),_c('v-divider',{staticClass:"my-3"}),_vm._l((_vm.staticMenuItems),function(item){return _c('v-list-item',{key:item.key,attrs:{"to":item.to}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)})],2)])],1)],1),_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.getIsAuthenticated),expression:"getIsAuthenticated"}],staticClass:"primary",staticStyle:{"margin":"5px"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}})],1),_c('v-navigation-drawer',{staticStyle:{"width":"200px"},attrs:{"clipped":_vm.clipped,"app":"","right":"","disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',{staticClass:"background",attrs:{"tile":"","height":"100%"}},[_c('div',{staticClass:"mb-4 col"},[(_vm.isAuthenticated)?_c('v-list',{attrs:{"ripple":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('LocaleSelector',{ref:"localeSelector"})],1)],1):_vm._e(),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSitesList),expression:"showSitesList"}],staticClass:"my-3"}),(_vm.isAuthenticated)?_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSitesList),expression:"showSitesList"}],attrs:{"ripple":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-select',{attrs:{"items":_vm.getSitesList,"label":_vm.translate(
									'Switch Site',
									'comp_ScanningOptions_RF_SwitchSite'
								),"item-text":"SiteName","item-value":"SiteID"},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.$t(slotProps.item.SiteName))+" ")]}}],null,false,3895545121),model:{value:(_vm.getActiveSiteID),callback:function ($$v) {_vm.getActiveSiteID=$$v},expression:"getActiveSiteID"}})],1)],1):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }