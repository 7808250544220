<template>
	<nav id="nav">
		<v-app-bar app class="nav-header header" :clipped-right="clipped">
			<div>
				<router-link to="/">
					<img
						src="/img/headerSavant_new.png"
						width="50"
						height="45"
						alt="logo"
						class="ma-4"
					/>
				</router-link>
			</div>
			<span>{{ getUserSiteDescription() }}</span>
			<v-spacer />
			<v-menu bottom offset-y nudge-right>
				<template #activator="{ on }">
					<v-btn
						v-show="getIsAuthenticated"
						icon
						data-account
						v-on="on"
					>
						<v-avatar v-if="hasAvatarImage" color="primary">
							<v-img :src="getCurrentUserAvatarImage" />
						</v-avatar>
						<v-avatar v-else color="primary">
							<span class="text-h5">{{
								getUsersInitialsForBadge
							}}</span>
						</v-avatar>
					</v-btn>
				</template>

				<v-card style="padding: 10px" class="background">
					<v-list-item-content class="justify-left">
						<div class="mx-auto text-left">
							<h3>{{ getUserFullName() }}</h3>
							<p class="text-caption mt-1">
								{{ getUsersEmail() }}
							</p>

							<v-divider />
							<v-container
								bg
								fill-height
								grid-list-md
								text-xs-center
								style="padding: 0"
							>
								<v-layout>
									<v-flex
										style="
											padding-left: 15px;
											padding-top: 0;
										"
										class="justify-left"
									>
										<v-switch
											v-model="$vuetify.theme.dark"
											color="primary"
											style="padding: 0"
											inset
											:label="
												translate(
													'Dark Mode',
													'comp_NavBar_DarkMode'
												)
											"
											@change="toggleDarkMode"
										/>
									</v-flex>
								</v-layout>
							</v-container>
							<v-flex
								style="padding-left: 15px; padding-top: 0"
								class="justify-left"
							>
								<v-switch
									color="primary"
									style="padding: 0"
									inset
									:label="
										translate(
											'Workflow Menu',
											'comp_NavBar_WorkFlowMenu'
										)
									"
									@change="toggleWorkflowMenu"
								/>
							</v-flex>

							<v-divider class="my-3" />
							<v-list-item
								v-for="item in staticMenuItems"
								:key="item.key"
								:to="item.to"
							>
								<v-icon class="mr-1">
									{{ item.icon }}
								</v-icon>
								<v-list-item-title>{{
									item.name
								}}</v-list-item-title>
							</v-list-item>
						</div>
					</v-list-item-content>
				</v-card>
			</v-menu>
			<v-app-bar-nav-icon
				v-show="getIsAuthenticated"
				class="primary"
				style="margin: 5px"
				@click.stop="toggleMenu"
			/>
		</v-app-bar>
		<v-navigation-drawer
			v-model="drawer"
			:clipped="clipped"
			app
			right
			style="width: 200px"
			disable-resize-watcher
		>
			<v-card tile class="background" height="100%">
				<div class="mb-4 col">
					<v-list v-if="isAuthenticated" ripple dense>
						<v-list-item-group color="primary">
							<LocaleSelector ref="localeSelector" />
						</v-list-item-group>
					</v-list>
					<v-divider v-show="showSitesList" class="my-3" />
					<v-list
						v-if="isAuthenticated"
						v-show="showSitesList"
						ripple
						dense
					>
						<v-list-item-group color="primary">
							<v-select
								v-model="getActiveSiteID"
								:items="getSitesList"
								:label="
									translate(
										'Switch Site',
										'comp_ScanningOptions_RF_SwitchSite'
									)
								"
								item-text="SiteName"
								item-value="SiteID"
							>
								<template #item="slotProps">
									{{ $t(slotProps.item.SiteName) }}
								</template>
							</v-select>
						</v-list-item-group>
					</v-list>
				</div>
			</v-card>
		</v-navigation-drawer>
	</nav>
</template>
<script>
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import LocaleSelector from './localeSelector.vue';
export default {
	name: 'NavBar',
	components: {
		LocaleSelector
	},
	data() {
		var self = this;
		return {
			clipped: true,
			drawer: false,
			usersInitials: '',
			staticMenuItems: [
				{
					key: 'themeEditor',
					name: self.translate(
						self.translate(
							'Theme Editor',
							'comp_NavBar_ThemeEditor'
						),
						'comp_ColorSettingsv2'
					),
					icon: 'mdi-palette',
					to: 'themeEditor'
				},
				{
					key: 'logoutin',
					name: this.isAuthenticated
						? self.translate('Log Out', 'comp_NavBar_LogOut')
						: self.translate('Log In', 'comp_NavBar_LogIn'),
					icon: 'mdi-login',
					to: this.isAuthenticated ? 'logout' : 'login'
				}
			]
		};
	},
	computed: {
		hasAvatarImage() {
			if (this.getCurrentOperatorProfile()?.AvatarImage) {
				return true;
			}
			return false;
		},
		getUsersInitialsForBadge() {
			return this.getUserInitials();
		},
		getCurrentUserEmail() {
			return this.getCurrentUser() == null
				? ''
				: this.getCurrentUser().Email;
		},
		getIsAuthenticated() {
			return this.isAuthenticated();
		},
		getSitesList() {
			return this.getUserSitesList();
		},
		showSitesList() {
			return this.getUserSitesList().length > 1;
		},
		getActiveSiteID() {
			return this.getUserSitesList().find(
				(item) => item.SiteID == this.getActiveSite()
			);
		},
		getCurrentUserAvatarImage() {
			return this.getCurrentOperatorProfile() == null
				? ''
				: this.getCurrentOperatorProfile().AvatarImage;
		}
	},
	mounted() {
		const locale = localStorage.getItem('custom_i18n_locale');
		if (locale) {
			this.$i18n.locale = locale;
		}
	},
	methods: {
		...mapState(useAuthStore, ['isAuthenticated', 'getCurrentUser']),
		...mapState(useUserStore, [
			'getActiveSite',
			'getUserInitials',
			'getUsersEmail',
			'getCurrentOperatorProfile',
			'getUserSitesList',
			'getUserSiteDescription',
			'getUserFullName'
		]),
		...mapActions(useUserStore, ['updateShowWorkFlowMenu']),
		toggleMenu() {
			this.drawer = !this.drawer;
		},
		toggleDarkMode: function () {
			localStorage.setItem(
				'dark_theme',
				this.$vuetify.theme.dark.toString()
			);
		},
		toggleWorkflowMenu: function (value) {
			this.updateShowWorkFlowMenu(value);
		},
		onLogout: function () {
			this.$router.push({ path: '/logout' });
		},
		translate(defaultText, localizeText) {
			return this.$options.filters.localizeText(
				defaultText,
				localizeText,
				this
			);
		}
	}
};
</script>
