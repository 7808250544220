import { HttpForAuthRequests } from '@/services/http-command';
import { defineStore } from 'pinia';

const externalAuthenticationController =
	'/mobileapi/externalauthentication/getExternalAuthenticationDetails/';

export const useScannerStore = defineStore('ScannerFunctionStore', {
	state: () => {
		return {
			lastApiResponse: [],
			allApiResponse: [],
			lastApiErrorState: {},
			allUserInputs: [],
			currentStep: null,
			currentWorkflowId: null,
			isApiRequestInProgress: false,
			showModal: null,
			modelComponentPayload: null
		};
	},
	persist: true,
	getters: {
		getLastApiResponse() {
			return this.lastApiResponse;
		},
		getPreviousResponses() {
			return this.allUserInputs;
		},
		isApiInError() {
			return this.lastApiErrorState.state;
		},
		apiErrorMessage() {
			return this.lastApiErrorState.message;
		},
		getAllApiResponses() {
			return this.allApiResponse;
		},
		getCurrentStep() {
			return this.currentStep;
		},
		getApiRequestInProgress() {
			return this.isApiRequestInProgress;
		},
		getShowModalValue() {
			return this.showModal;
		},
		getModelComponentPayload() {
			return this.modelComponentPayload;
		},
		getCurrentWorkflowAndStep() {
			return {
				workflowId: this.currentWorkflowId,
				workflowStep: this.currentStep
			};
		}
	},
	actions: {
		runApiGetRequest(payload) {
			return new Promise((resolve, reject) => {
				this.isApiRequestInProgress = true;
				HttpForAuthRequests()
					.get(payload.url)
					.then((res) => {
						this.updateLastApiResponse(res.data);
						this.setAllUserInputs(payload.formInput);
						this.setApiErrorStatus({ state: false, message: null });
						resolve();
					})
					.catch((e) => {
						this.updateLastApiResponse(null);
						if (e.response) {
							this.setApiErrorStatus({
								state: true,
								message: e.response.statusText
							});
						} else {
							this.setApiErrorStatus({
								state: true,
								message: 'An unknown error has occured'
							});
						}
						reject(e);
					})
					.finally(() => {
						this.isApiRequestInProgress = false;
					});
			});
		},
		runApiPostRequest(payload) {
			return new Promise((resolve, reject) => {
				this.isApiRequestInProgress = true;
				HttpForAuthRequests()
					.post(payload.url, payload.body)
					.then((res) => {
						this.updateLastApiResponse(res.data);
						this.setAllUserInputs(payload.formInput);
						this.setApiErrorStatus({ state: false, message: null });
						resolve();
					})
					.catch((e) => {
						this.updateLastApiResponse(null);
						if (e.response) {
							this.setApiErrorStatus({
								state: true,
								message: e.response.statusText
							});
						} else {
							this.setApiErrorStatus({
								state: true,
								message: 'An unknown error has occured'
							});
						}
						reject(e);
					})
					.finally(() => {
						this.isApiRequestInProgress = false;
					});
			});
		},
		runExternalApiRequest(payload) {
			return new Promise((resolve, reject) => {
				this.isApiRequestInProgress = true;
				HttpForAuthRequests()
					.post(externalAuthenticationController, payload.requestBody)
					.then((res) => {
						var responseObject = JSON.parse(res.data.ResponseBody);
						this.updateLastApiResponse(responseObject);
						this.setAllUserInputs(payload.formInput);
						this.setApiErrorStatus({ state: false, message: null });
						resolve();
					})
					.catch((e) => {
						this.updateLastApiResponse(null);
						if (e.response) {
							this.setApiErrorStatus({
								state: true,
								message: e.response.statusText
							});
						} else {
							this.setApiErrorStatus({
								state: true,
								message: 'An unknown error has occured'
							});
						}
						reject(e);
					})
					.finally(() => {
						this.isApiRequestInProgress = false;
					});
			});
		},
		removeUserInputAfterStep(payload) {
			//this works by taking in the step we are reseting to and removing everything after that step
			var cloned = JSON.parse(JSON.stringify(this.allUserInputs));
			var itemsToRemove = cloned.length - payload.stepToResetFrom + 1;
			var startIndex = cloned.length - itemsToRemove;
			cloned.splice(startIndex, itemsToRemove);

			this.allUserInputs = cloned;

			if (payload.resetApiResponses) {
				if (payload.resetApiResponsesAfterStep == -1) {
					// everything
					this.allApiResponse = [];
				} else {
					// from a specific step
					cloned = JSON.parse(JSON.stringify(this.allApiResponse));
					itemsToRemove =
						cloned.length - payload.resetApiResponsesAfterStep + 1;
					startIndex = cloned.length - itemsToRemove;
					cloned.splice(startIndex, itemsToRemove);

					this.allApiResponse = cloned;
				}
			}
		},
		removePayloads() {
			this.lastApiResponse = null;
			this.lastApiErrorState = null;
			this.allUserInputs = null;
			this.allApiResponse = null;
		},
		goBackStep() {
			var originalStep = this.currentStep;
			this.currentStep = this.currentStep - 1;
			this.removeUserInputAfterStep({ stepToResetFrom: originalStep });
		},
		goBackToStart() {
			this.currentStep = -1;
			this.removeUserInputAfterStep(1);
		},
		setWorkflowStep(payload) {
			this.currentStep = payload.currentStep;
			this.currentWorkflowId = payload.currentWorkflowId;
		},
		setIterationStepResponse(payload) {
			var collectionValue = payload.value;
			var cloned = null;

			//go see if we can find out collection key value
			this.allUserInputs.forEach((savedResponse) => {
				//ok we need to nest here again, as we have an array of arrays for each step
				savedResponse.forEach((currentResponse) => {
					if (currentResponse.key == payload.collectionName) {
						if (currentResponse.value) {
							//not an empty value, so we need to add our existing stuff and a pipe
							collectionValue =
								currentResponse.value + '|' + collectionValue;
						}

						//ok nasty as this is, we are taking the user input AND the collection key and removing them both
						cloned = JSON.parse(JSON.stringify(this.allUserInputs));
						cloned.splice(cloned.length - 2, 2);
						cloned.push([
							{
								key: payload.collectionName,
								value: collectionValue
							}
						]);
					}
				});
			});

			if (Array.isArray(cloned)) {
				this.allUserInputs = cloned;
			} else {
				this.allUserInputs.push([
					{ key: payload.collectionName, value: collectionValue }
				]);
			}
		},
		removeItemFromIterationInput(payload) {
			var removeTheWholeKey = false;
			var responseObject = JSON.parse(payload);
			var keyToFind = responseObject.iterationKey;
			var valueToRemove = responseObject.selectedRow;
			var newIterationValue = '';

			//iterate the user inputs to find out key
			this.allUserInputs.forEach((savedResponse) => {
				savedResponse.forEach((currentResponse) => {
					if (currentResponse.key == keyToFind) {
						var valueArray = currentResponse.value.split('|');

						//If we only have one value in the array then stop right there and just remove the whole key from the state
						if (valueArray.length === 1) {
							removeTheWholeKey = true;
						} else {
							//if we have more then one remove the item from the array then string.join it back currentResponse.value = newValue should work
							var updatedArray = valueArray.filter(
								(item) => item !== valueToRemove
							);
							var newValue = updatedArray.join('|');
							newIterationValue = newValue;
						}
					}
				});
			});

			//clear the iteration key out
			var cloned = JSON.parse(JSON.stringify(this.allUserInputs));
			cloned.splice(cloned.length - 1, 1);
			this.allUserInputs = cloned;

			//we already had more than one value, so bang the new one back in
			if (!removeTheWholeKey && newIterationValue) {
				this.allUserInputs.push([
					{ key: keyToFind, value: newIterationValue }
				]);
			} else {
				//othewise just put an empty entry in so the UI reacts
				this.allUserInputs.push([{ key: keyToFind, value: '' }]);
			}
		},
		updateModalState(payload) {
			this.showModal = !this.showModal;

			this.modelComponentPayload = null;
			var payloadObject = JSON.parse(payload);

			if (
				typeof payloadObject === 'object' &&
				!Array.isArray(payloadObject) &&
				payloadObject !== null
			) {
				this.modelComponentPayload = payloadObject;
			}
		},
		updateLastApiResponse(payload) {
			if (!Array.isArray(this.allApiResponse)) {
				this.allApiResponse = [];
			}
			this.lastApiResponse = payload;
			this.allApiResponse.push(payload);
		},
		setAllUserInputs(payload) {
			if (!Array.isArray(this.allUserInputs)) {
				this.allUserInputs = [];
			}

			if (payload) {
				this.allUserInputs.push(payload);
			}
		},
		setApiErrorStatus(payload) {
			this.lastApiErrorState = payload;
		}
	}
});
