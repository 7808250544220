/**
 * Sets up the users theme to use in local storage ready to use.
 * * @param {object} themeSettings Theme settings object.
 */

export const CreateUserTheme = function (themeSettings) {
	var themeName =
		themeSettings.themeType == 2 ||
		themeSettings.themeType == 4 ||
		themeSettings.themeType == 6
			? 'user_dark_theme'
			: 'user_light_theme';

	var theme = {
		primary: themeSettings.primary,
		secondary: themeSettings.secondary,
		accent: themeSettings.accent,
		error: themeSettings.error,
		info: themeSettings.information,
		success: themeSettings.success,
		warning: themeSettings.warning,
		background: themeSettings.background,
		header: themeSettings.header,
		alternateTableRow: themeSettings.alternateTableRow
	};

	localStorage.setItem(themeName, JSON.stringify(theme));
};

/**
 * Applys a theme object to vuetify.
 * * @param {object} vm Virtual Machine.
 */
export const ApplyUserTheme = function (vm) {
	const isDark = vm.$vuetify.theme.dark;
	const themeSettings = isDark
		? JSON.parse(localStorage.getItem('user_dark_theme'))
		: JSON.parse(localStorage.getItem('user_light_theme'));

	Object.keys(themeSettings).forEach((i) => {
		if (themeSettings[i]) {
			if (isDark) {
				vm.$vuetify.theme.themes.dark[i] = themeSettings[i];
			} else {
				vm.$vuetify.theme.themes.light[i] = themeSettings[i];
			}
		}
	});
};

/**
 * Does the theme exist and look valid.
 * * @param {string} themeName name of the theme in local storage.
 */
export const DoesThemeExist = function (themeName) {
	const theme = JSON.parse(localStorage.getItem(themeName));

	if (theme === null) {
		return false;
	}

	if (Object.keys(theme).length === 0) {
		return false;
	}

	if (theme?.info === null) {
		return false;
	}

	return true;
};
