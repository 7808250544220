export default {
	methods: {
		getValueFromNestedArray(arrayToCheck, keyToFind) {
			var foundValue = '';
			arrayToCheck.forEach((savedResponse) => {
				//ok we need to nest here as we have an array of arrays for each step
				savedResponse.forEach((currentResponse) => {
					if (currentResponse.key === keyToFind) {
						foundValue = currentResponse.value;
					}
				});
			});
			return foundValue;
		},
		swapTokenForValuesFromObject(payload, keyPadding, objectToSearch) {
			if (objectToSearch === null) {
				return payload;
			}

			if (Array.isArray(objectToSearch)) {
				for (let key in objectToSearch[0]) {
					var tokenToFind = `${keyPadding}${key}${keyPadding}`;
					payload = payload.replace(
						tokenToFind,
						objectToSearch[0][key]
					);
				}
			}

			if (typeof objectToSearch === 'object') {
				Object.keys(objectToSearch).forEach(function (key) {
					if (Array.isArray(objectToSearch[key])) {
						var tokenCollectionToFind = `${keyPadding}${key}${keyPadding}[]`;

						var arrayToIterate = JSON.stringify(
							objectToSearch[key]
						);
						payload = payload.replace(
							tokenCollectionToFind,
							arrayToIterate
						);
					} else {
						var tokenToFind = '#' + key + '#';
						payload = payload.replace(
							tokenToFind,
							objectToSearch[key]
						);
					}
				});
			}
			return payload;
		}
	}
};
