//Pina auth store converted from vuex
import {
	HttpForNonAuthRequests,
	HttpForAuthRequests
} from '@/services/http-command';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('AuthStore', {
	state: () => {
		return {
			sessionProfile: {
				access_token: sessionStorage.getItem('access_token') || null,
				refresh_token: sessionStorage.getItem('refresh_token') || null,
				currentAccess: sessionStorage.getItem('currentAccess') || null,
				userId: sessionStorage.getItem('userId') || null
			},
			lastExternalAuthenticationApiResponse: null,
			showSplashScreen: sessionStorage.getItem('showSplashScreen') || true
		};
	},
	getters: {
		isAuthenticated() {
			if (this.sessionProfile.access_token !== null) {
				return true;
			}
			return false;
		},
		authToken() {
			return this.sessionProfile.access_token;
		},
		getUserId() {
			return this.sessionProfile.userId;
		},
		getLastExternalAuthenticationApiResponse() {
			return this.lastExternalAuthenticationApiResponse;
		},
		getShowSplashScreen() {
			return this.showSplashScreen ?? true;
		}
	},
	actions: {
		login(credentials) {
			const params = new URLSearchParams();
			params.append('username', credentials.username);
			params.append('password', credentials.password);
			params.append('grant_type', credentials.grantType);
			params.append('siteid', credentials.site);
			params.append('client_id', credentials.clientId);
			params.append('client_secret', credentials.clientSecret);
			return new Promise((resolve, reject) => {
				HttpForNonAuthRequests()
					.post('/oauth/token', params)
					.then((res) => {
						if (res.data != null) {
							sessionStorage.setItem(
								'access_token',
								res.data.access_token
							);
							this.sessionProfile.access_token =
								res.data.access_token;
							this.sessionProfile.refresh_token =
								res.data.refresh_token;
							sessionStorage.setItem(
								'refresh_token',
								res.data.refresh_token
							);
							this.sessionProfile.currentAccess =
								res.data.CurrentAccess;
							sessionStorage.setItem(
								'currentAccess',
								res.data.CurrentAccess
							);
							this.sessionProfile.userId = res.data.UserId;
							sessionStorage.setItem('userId', res.data.UserId);
						}
						resolve(res.data);
					})
					.catch((e) => {
						this.sessionProfile.access_token = null;
						this.sessionProfile.refresh_token = null;
						this.sessionProfile.currentAccess = null;
						this.sessionProfile.userId = null;
						reject(e);
					});
			});
		},
		logout(operatorId) {
			//call the logout API to make sure our users is cleared from the operator table
			HttpForAuthRequests().patch(
				`/mobileapi/accounts/logout/${operatorId}/`
			);
			this.sessionProfile.access_token = null;
			this.sessionProfile.refresh_token = null;
			this.sessionProfile.currentAccess = null;
			this.sessionProfile.userId = null;
		},
		setShowSplashScreen(value) {
			this.showSplashScreen = value;
			sessionStorage.setItem('showSplashScreen', value);
		}
	}
});
