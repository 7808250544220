<template>
	<div>
		<ScanningOptions />
	</div>
</template>

<script>
import ScanningOptions from '../components/Home/ScanningOptions.vue';

export default {
	name: 'HomeView',
	components: {
		ScanningOptions
	}
};
</script>
