export const ShouldSkipStep = function (
	stepConditions,
	apiResponse,
	allApiResponse
) {
	//no critera, so we skip
	if (stepConditions?.length <= 0) return true;
	//for now we are going to assume that all our critera need to pass,
	//so if we have one fail then return true right away
	for (const condition of stepConditions) {
		//pull the data out we need to check on
		var propertyVal = swapApiTokensForSingleObject(
			condition.propertyToCheck,
			apiResponse,
			allApiResponse
		);

		if (!propertyVal) return true;
		//we got a value, so lets switch on the operator and if we fail return true
		switch (condition.checkOperator) {
			case '>':
				if (
					!operatorFunctionGreaterThan(
						propertyVal,
						condition.checkValue
					)
				)
					return true;
				break;
			case '<':
				if (
					!operatorFunctionLessThan(propertyVal, condition.checkValue)
				)
					return true;
				break;
			case '==':
				if (!operatorFunctionEquals(propertyVal, condition.checkValue))
					return true;
				break;
			default:
				return true;
		}
	}

	//ok if we got this far then we're gonna asume nothing failed, so we dont want to skip
	return false;
};

function swapApiTokensForSingleObject(payload, apiResponse, allApiResponse) {
	if (apiResponse === null) {
		return payload;
	}

	if (!Array.isArray(apiResponse) && apiResponse[payload]) {
		payload = payload.replace(payload, apiResponse[payload]);

		//force a bool if we are 'true' or 'false'
		if (payload === 'true' || payload === 'false') {
			payload = payload === 'true';
		}
		return payload;
	}

	//ok not found in the last response, so lets check our previous stuff
	//go and get them all out, then extract everything out that is an object.
	//hopefully then we can use a key/value check to get our value out

	if (allApiResponse.length === 0) {
		return payload;
	}

	allApiResponse.forEach(function (item) {
		if (typeof item === 'object' && item !== null) {
			//we are an object, so lets see if we can find our key
			if (item[payload]) {
				payload = item[payload];

				//force a bool if we are 'true' or 'false'
				if (payload === 'true' || payload === 'false') {
					payload = payload === 'true';
				}
			}
		}
	});

	return payload;
}

function operatorFunctionGreaterThan(propertyValToCheck, checkAgaistValue) {
	return propertyValToCheck > checkAgaistValue;
}

function operatorFunctionLessThan(propertyValToCheck, checkAgaistValue) {
	return propertyValToCheck < checkAgaistValue;
}

function operatorFunctionEquals(propertyValToCheck, checkAgaistValue) {
	return propertyValToCheck === checkAgaistValue;
}
