<!-- eslint-disable vue/no-v-html -->
<template>
	<v-row no-gutters>
		<v-col cols="sm">
			<v-card
				class="pt-1 pl-2 labelDisplayBox"
				outlined
				tile
				color="background"
			>
				<span data-lableBox v-html="getLableText"> </span>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from 'pinia';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import {
	parseToFormatedText,
	swapOutCreatedTextElementToUserString,
	FormatTextWithFomaterOptions
} from '@/services/textFormattingEngine';

export default {
	name: 'LabelDisplayBox',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	computed: {
		getLableText() {
			var textToReturn = this.inputBox.FormattedText;
			var textObject = parseToFormatedText(textToReturn);
			var functionToPerform = this.inputBox.ValueFunction ?? '';
			var vm = this;

			// roll over our items to tokenise and try and get each one
			textObject.itemsToTokenize.forEach(function (item) {
				var rawText = vm.getLableTextFromAllApiResponses(
					item.key,
					functionToPerform
				);

				if (rawText && functionToPerform) {
					// just return back, we've no way of formatting at the minute
					item.outputText = rawText;
					return;
				}

				if (rawText) {
					rawText = FormatTextWithFomaterOptions(
						rawText,
						item.formatters
					);
					item.outputText = rawText;
					return;
				}

				rawText = vm.getLableTextFromUserResponses(item.key);
				if (rawText) {
					rawText = FormatTextWithFomaterOptions(
						rawText,
						item.formatters
					);
					item.outputText = rawText;
					return;
				}
			});

			// swap out our input text into all our new component parts
			textToReturn = swapOutCreatedTextElementToUserString(
				textObject.itemToLocalise,
				textToReturn
			);

			textToReturn = swapOutCreatedTextElementToUserString(
				textObject.itemsToTokenize,
				textToReturn
			);

			return textToReturn;
		}
	},
	methods: {
		...mapState(useScannerStore, [
			'getPreviousResponses',
			'getAllApiResponses',
			'getLastApiResponse'
		]),

		getLableTextFromAllApiResponses(propertyToFind, functionToPerform) {
			if (functionToPerform) {
				switch (functionToPerform.toUpperCase()) {
					case 'SUM':
						return this.getSumFunctionValue(propertyToFind);
				}
			}

			var allApiResponses = this.getAllApiResponses();

			var textToReturn = '';

			if (!allApiResponses || allApiResponses.lengh === 0) {
				return textToReturn;
			}

			allApiResponses.forEach(function (item) {
				var itemToCheck = item;

				if (Array.isArray(item)) {
					itemToCheck = item[0];
				}

				if (typeof itemToCheck === 'object' && itemToCheck !== null) {
					//we are an object, so lets see if we can find our key
					if (itemToCheck[propertyToFind]) {
						textToReturn = itemToCheck[propertyToFind];
					}
				}
			});

			return textToReturn;
		},

		getLableTextFromUserResponses(propertyToFind) {
			var allApiUserResponses = this.getPreviousResponses();
			var textToReturn = '';

			allApiUserResponses.forEach((responseStep) => {
				//ok we need to nest here again, as we have an array of arrays for each step
				responseStep.forEach((response) => {
					if (response) {
						if (
							propertyToFind.toUpperCase() ===
							response.key.toUpperCase()
						) {
							textToReturn = response.value;
						}
					}
				});
			});

			return textToReturn;
		},

		getSumFunctionValue(propertyToSum) {
			var apiResponse = this.getLastApiResponse();

			//sum up all the property in the array
			return apiResponse
				.map((item) => item[propertyToSum])
				.reduce((prev, curr) => prev + curr, 0);
		}
	}
};
</script>
