<template>
	<div class="lang-dropdown">
		<v-select
			v-model="$i18n.locale"
			:items="languageArray"
			:label="$t('comp_NavBar_Language')"
			item-text="name"
			@change="updateLanguage()"
		>
			<template #selection="{ item }">
				<i :class="['mr-2', 'em', item.flag]" />
				{{ $t(item.text) }}
			</template>
			<template #item="slotProps">
				<i :class="['mr-2', 'em', slotProps.item.flag]" />
				{{ $t(slotProps.item.text) }}
			</template>
		</v-select>
	</div>
</template>

<script>
import { loadLocaleFromApi } from '@/plugins/i18n';
import { useUserStore } from '@/stores/userStore';
import { mapActions } from 'pinia';
export default {
	name: 'LocaleSelector',
	data: () => ({
		// eslint-disable-next-line
		languageArray: [
			{
				name: 'en',
				text: 'comon_English',
				flag: 'em-flag-us'
			},
			{
				name: 'es',
				text: 'comon_Spanish',
				flag: 'em-flag-mx'
			},
			{
				name: 'fr',
				text: 'comon_French',
				flag: 'em-flag-fr'
			}
		]
	}),
	methods: {
		...mapActions(useUserStore, ['setUserLocal']),
		async updateLanguage() {
			// set the new lang in our store so we persist it
			this.setUserLocal(this.$i18n.locale);

			var langArrayItem = this.languageArray.find(
				(l) => l.name === this.$i18n.locale
			);
			var savantCode = this.$t(langArrayItem.text);
			this.setUserLocal(this.$i18n.locale, savantCode);

			// load the locals
			await loadLocaleFromApi(this.$i18n.locale);
		}
	}
};
</script>
