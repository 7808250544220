<template>
	<v-container fluid>
		<v-card class="mx-auto" tile>
			<v-list width="100%" style="padding: 0">
				<v-list-item
					v-for="(item, i) in getListData"
					:key="i"
					style="border-bottom: 1px solid #ccc"
					three-line
					@click="rowClick(item)"
				>
					<v-list-item-content>
						<v-list-item-title>
							<!--eslint-disable-next-line-->
							<span v-html="item.title"> </span>
						</v-list-item-title>
						<v-list-item-subtitle class="text--primary">
							<!--eslint-disable-next-line-->
							<span v-html="item.subtitle"></span>
						</v-list-item-subtitle>
						<v-list-item-subtitle>
							<!--eslint-disable-next-line-->
							<span v-html="item.details"></span>
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
	</v-container>
</template>

<script setup>
import { defineProps, defineEmits, computed, getCurrentInstance } from 'vue';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import { getNestedPropertyValue } from '@/services/getNestedValues';
import {
	parseToFormatedText,
	FormatTextWithFomaterOptions,
	swapOutCreatedTextElementToUserString
} from '@/services/textFormattingEngine';

const scannerStore = useScannerStore();
const emit = defineEmits(['listItemSelected']);
const vm = getCurrentInstance();

const props = defineProps({
	inputBox: {
		type: Object,
		required: true
	}
});

const getListData = computed(() => {
	var listData = [];
	const lastApiResponse = scannerStore.getLastApiResponse;

	if (
		lastApiResponse === null ||
		lastApiResponse.length === 0 ||
		!Array.isArray(lastApiResponse)
	) {
		return listData;
	}

	var line1TextToSwapOut = props.inputBox.Line1.FormattedText;
	var line2TextToSwapOut = props.inputBox.Line2.FormattedText;
	var line3TextToSwapOut = props.inputBox.Line3.FormattedText;

	lastApiResponse.forEach(function (responseRow) {
		var title = getTextLineFromConfig(
			parseToFormatedText(props.inputBox.Line1.FormattedText),
			responseRow,
			line1TextToSwapOut
		);
		var subtitle = getTextLineFromConfig(
			parseToFormatedText(props.inputBox.Line2.FormattedText),
			responseRow,
			line2TextToSwapOut
		);
		var details = getTextLineFromConfig(
			parseToFormatedText(props.inputBox.Line3.FormattedText),
			responseRow,
			line3TextToSwapOut
		);

		var keyValue = stripTokenChars(props.inputBox.Key);
		keyValue = getNestedPropertyValue(keyValue, responseRow);

		listData.push({
			title: title,
			subtitle: subtitle,
			details: details,
			key: keyValue
		});
	});

	return listData;
});

const getTextLineFromConfig = (configObject, rowData, textToSwapOutFrom) => {
	if (!configObject.itemsToTokenize) {
		return '';
	}

	configObject.itemsToTokenize.forEach(function (item) {
		var itemValue = '';

		if (typeof item === 'object' && item !== null) {
			if (rowData[item.key]) {
				itemValue = rowData[item.key];
				if (itemValue) {
					itemValue = FormatTextWithFomaterOptions(
						itemValue,
						item.formatters,
						vm
					);
					item.outputText = itemValue;
				}
			}
		}
	});

	textToSwapOutFrom = swapOutCreatedTextElementToUserString(
		configObject.itemToLocalise,
		textToSwapOutFrom
	);

	textToSwapOutFrom = swapOutCreatedTextElementToUserString(
		configObject.itemsToTokenize,
		textToSwapOutFrom
	);

	return textToSwapOutFrom;
};

const stripTokenChars = (interopString) => {
	if (!interopString) {
		return interopString;
	}

	if (interopString.startsWith('#') && interopString.endsWith('#')) {
		interopString = interopString.slice(1, -1);
	}

	return interopString;
};

const rowClick = (item) => {
	if (!props.inputBox.AllowUserRowClickEvent) {
		return;
	}

	const submitAfterClickText = props.inputBox.CanRowSelectSubmitForm
		? ':SubmitForm'
		: '';

	emit(
		'inputUpdate',
		props.inputBox.Name + ':' + item.key + submitAfterClickText
	);
};
</script>
