import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import Vue from 'vue';
import i18n from '@/plugins/i18n';
import { playSound } from './responseSound';

// http axios client for authenticated savant API requests
export const HttpForAuthRequests = function () {
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const userName = authStore.getUserId;
	const siteId = userStore.getActiveSite;

	var client = axios.create({
		baseURL: process.env.VUE_APP_ROOT_API,
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${authStore.authToken}`,
			'x-savant-username': userName,
			'x-savant-siteId': siteId
		}
	});

	client.interceptors.request.use((config) => {
		Vue.prototype.$Progress.start();
		return config;
	});

	client.interceptors.response.use(
		function (response) {
			Vue.prototype.$Progress.finish();
			playSound(false);
			return response;
		},
		function (error) {
			Vue.prototype.$Progress.fail();
			//here lets handle a 403 so we direct back to the login page
			const statusCode = error.response.status;
			if (statusCode === 401 || statusCode === 403) {
				window.location = '/login';
			}

			playSound(true);

			// default the error to the title, then see if we have a translation key
			var toastTitle = error.response.data?.Title
				? error.response.data?.Title
				: error.response.statusText;

			if (error.response.data?.LocalisedTitleKey) {
				var tokenList = error.response.data?.LocalisedTitleData ?? [];
				toastTitle = i18n.t(
					error.response.data?.LocalisedTitleKey,
					tokenList
				);
			}

			Vue.$toast.error(toastTitle, {
				timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
			});

			return Promise.reject(error);
		}
	);

	return client;
};

export const HttpForNonAuthRequests = function () {
	var client = axios.create({
		baseURL: process.env.VUE_APP_ROOT_API,
		headers: {
			'Content-Type': 'application/json',
			'x-savant-scanner': 'true'
		}
	});

	client.interceptors.request.use((config) => {
		Vue.prototype.$Progress.start();
		return config;
	});

	client.interceptors.response.use(
		function (response) {
			Vue.prototype.$Progress.finish();
			return response;
		},
		function (error) {
			Vue.prototype.$Progress.fail();

			var toastTitle =
				'Problem logging on. Check credentials and try again';
			Vue.$toast.error(toastTitle, {
				timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
			});

			return Promise.reject(error);
		}
	);

	return client;
};
