/**
 * Translate a text key.
 * @param {string} defaultText Default text to show if translation fails.
 * @param {string} localizeString Key of the text we want to localize.
 * @param {object} vm vm object.
 */
export const localizeText = (defaultText, localizeString, vm) => {
	if (!localizeString && !defaultText) return 'Invalid text string setting';

	if (!localizeString) return defaultText;

	var localizedText = vm.$t(localizeString);

	if (localizedText !== localizeString) return localizedText;

	return defaultText;
};
