/**
 * Gets value of a nested property value by periods in the key string
 * @param {string} valueKey The string of the item in the object to get the value for.
 * @param {object} objectSource The object to look for the value in.
 * @return {string} the string of the value, otherwise null if not found.
 */
export const getNestedPropertyValue = function (valueKey, objectSource) {
	return valueKey.split('.').reduce(function (prev, curr) {
		return prev ? prev[curr] : null;
	}, objectSource || self);
};
