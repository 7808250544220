<template>
	<ThemeEditor />
</template>

<script>
import ThemeEditor from '../components/themeEditor.vue';

export default {
	name: 'ThemeEditorView',

	components: {
		ThemeEditor
	}
};
</script>
