<template>
	<v-row no-gutters align="center" justify="center">
		<v-data-table
			:headers="tableHeaders"
			:items="tableData"
			:mobile-breakpoint="0"
			:hide-default-footer="true"
			:dense="true"
			disable-items-per-page
			disable-pagination
			data-tabularData
			hide-default-header
			class="custom_table_class"
		>
			<template #header="{ props: { headers } }">
				<thead>
					<tr>
						<th
							v-for="(h, i) in headers"
							:key="i"
							class="header-style"
						>
							<span>{{ h.text }}</span>
						</th>
					</tr>
				</thead>
			</template>
			<template
				v-if="isIterateField"
				slot="item.action"
				slot-scope="props"
			>
				<v-btn
					class="mx-2"
					small
					:color="iterationButtonSettings.colour"
					@click="onActionTrigger(props.item)"
				>
					<v-icon color="grey">
						{{
							inputConfigAsObject.RowActionButtons[0].component
								.icon
						}}
					</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-row>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import { getNestedPropertyValue } from '@/services/getNestedValues';
import Vue from 'vue';
export default {
	name: 'TabularData',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		iterationValue: null
	}),
	computed: {
		inputConfigAsObject() {
			return JSON.parse(JSON.stringify(this.inputBox));
		},
		isIterateField() {
			return this.inputConfigAsObject.IsDataSourceIteratingField;
		},
		sortedTableFields() {
			//we cant mutate our prop, so clone it then make sure its sorted
			var configFields = JSON.parse(JSON.stringify(this.inputBox.Fields));
			var sortedFields = configFields.sort((a, b) =>
				a.SortOrder > b.SortOrder ? 1 : -1
			);
			return sortedFields;
		},
		iterationButtonSettings() {
			var configFields = JSON.parse(JSON.stringify(this.inputBox));

			if (!configFields.RowActionButtons) {
				return {};
			}

			return configFields.RowActionButtons;
		},
		tableHeaders() {
			var tableFields = this.sortedTableFields;
			var tableHeaders = [];
			var vm = this;

			tableFields.forEach(function (item) {
				var headerText = Vue.prototype.$filters.localizeText(
					item.HeaderDefaultText,
					item.HeaderLocalizedText,
					vm
				);

				//alignment, shift numeric fileds right
				var colAlign = item.DataType === 'decimal' ? 'right' : 'left';

				tableHeaders.push({
					text: headerText,
					value: item.Key,
					sortable: false,
					align: colAlign
				});
			});

			//header for our iteration actions
			if (this.isIterateField) {
				tableHeaders.push({
					text: '',
					value: 'action',
					class: 'header-style'
				});
			}

			return tableHeaders;
		},
		tableData() {
			var tableFields = this.sortedTableFields;
			var tableData = [];
			var vm = this;

			var configFields = JSON.parse(JSON.stringify(this.inputBox));

			if (configFields.IsDataSourceIteratingField) {
				//we are using a iteration data field as a source
				var keyOfIteration = configFields.Fields[0].Key;
				var userResponses = this.getPreviousResponses();

				userResponses.forEach((responseStep) => {
					//ok we need to nest here again, as we have an array of arrays for each step
					responseStep.forEach((response) => {
						if (response) {
							if (
								keyOfIteration.toUpperCase() ===
								response.key.toUpperCase()
							) {
								this.iterationValue = response.value;
							}
						}
					});
				});

				if (this.iterationValue) {
					var splitArray = this.iterationValue.split('|');

					for (let i = 0; i < splitArray.length; i++) {
						var itemForTable = {};
						itemForTable[keyOfIteration] = splitArray[i];
						tableData.push(itemForTable);
					}
				}
			} else {
				//normal api data source, loop over each API response, then each of our keys and try to tie the 2 of them together
				var lastApiResponse = this.getLastApiResponse();

				if (lastApiResponse === null || lastApiResponse.length === 0) {
					return tableData;
				}

				lastApiResponse.forEach(function (responseRow) {
					var itemForTable = {};
					tableFields.forEach(function (tableField) {
						var rowValue = getNestedPropertyValue(
							tableField.Key,
							responseRow
						);

						if (rowValue) {
							//if we have a type option lets try and format it
							switch (tableField.DataType) {
								case 'date':
									rowValue = vm.$d(
										new Date(rowValue),
										'short'
									);
									break;
								case 'decimal':
									rowValue = vm.$n(rowValue, 'decimal');
									break;
							}

							itemForTable[tableField.Key] = rowValue;
						}
					});

					tableData.push(itemForTable);
				});

				if (tableData.length <= 1) {
					//lets try all our API responses
					return this.buildTableDataFromAllApiResponses();
				}
			}
			return tableData;
		}
	},
	methods: {
		...mapState(useScannerStore, [
			'getLastApiResponse',
			'getPreviousResponses',
			'getAllApiResponses'
		]),
		...mapState(useUserStore, ['getAllScannerActions']),
		...mapActions(useScannerStore, ['removeItemFromIterationInput']),
		buildTableDataFromAllApiResponses() {
			var allApiResponses = this.getAllApiResponses();
			var tableData = [];

			if (allApiResponses === null || allApiResponses.length === 0) {
				return tableData;
			}

			var tableFields = this.sortedTableFields;
			var vm = this;

			//track if we have hit the instance of what we are looking for, as there could be multiple in the responses cache
			var haveHitRow = false;

			allApiResponses.forEach(function (item) {
				if (Array.isArray(item) && !haveHitRow) {
					item.forEach(function (responseRow) {
						var itemForTable = {};
						tableFields.forEach(function (tableField) {
							if (responseRow[tableField.Key]) {
								var rowValue = responseRow[tableField.Key];

								//if we have a type option lets try and format it
								switch (tableField.DataType) {
									case 'date':
										rowValue = vm.$d(
											new Date(rowValue),
											'short'
										);
										break;
									case 'decimal':
										rowValue = vm.$n(rowValue, 'decimal');
										break;
								}
								itemForTable[tableField.Key] = rowValue;
								haveHitRow = true;
							}
						});
						tableData.push(itemForTable);
					});
				}
			});

			return tableData;
		},
		onActionTrigger(row) {
			var availableActions = this.getAllScannerActions();
			var actionToDispatch = availableActions.find(
				(o) =>
					o.id ===
					this.inputConfigAsObject.RowActionButtons[0].actionId
			);

			var actionPayload = this.createActionPayload(
				actionToDispatch.payload,
				row
			);

			if (actionToDispatch.storeName === 'scannerFunctionsStore') {
				if (
					actionToDispatch.storeAction ===
					'removeItemFromIterationInput'
				) {
					this.removeItemFromIterationInput(actionPayload).catch(
						(e) => {
							console.error(e);
						}
					);
				}
			}
		},
		createActionPayload(payloadSettings, rowItem) {
			var keyOfIteration = this.inputConfigAsObject.Fields[0].Key;
			var rowValue = rowItem[keyOfIteration];
			payloadSettings = payloadSettings.replace(
				'#selectedRow#',
				rowValue
			);
			payloadSettings = payloadSettings.replace(
				'#iterationKey#',
				keyOfIteration
			);
			return payloadSettings;
		}
	}
};
</script>

<style>
.header-style {
	color: black !important;
	font-weight: bolder;
}
.theme--light.custom_table_class thead th {
	background-color: rgb(224, 224, 224);
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
	background-color: var(--v-alternateTableRow-base);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
	background-color: var(--v-alternateTableRow-base);
}

table th + th {
	border-left: 1px solid #dddddd;
}
table td + td {
	border-left: 1px solid #dddddd;
}
</style>
