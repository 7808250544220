<template>
	<v-container>
		<v-row class="text-center">
			<v-col cols="12">
				{{
					translate(
						'Are you sure you want to logout?',
						'view_Logout_Message'
					)
				}}
			</v-col>
			<v-col cols="12">
				<v-btn color="primary" @click="handleLogout()">
					{{ translate('Yes', 'comon_Yes') }}
				</v-btn>
				&nbsp;&nbsp;&nbsp;
				<v-btn color="error" @click="handleNo()">
					{{ translate('No', 'comon_No') }}
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/userStore';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import Vue from 'vue';
export default {
	name: 'LogoutView',
	methods: {
		...mapState(useAuthStore, ['getUserId']),
		...mapActions(useAuthStore, ['logout']),
		...mapActions(useScannerStore, ['removePayloads']),
		...mapActions(useUserStore, ['removeUserPayloads']),
		handleLogout() {
			this.logout(this.getUserId());
			this.removePayloads();
			this.removeUserPayloads();
			sessionStorage.clear();
			this.$router.push({ path: '/login' });
		},
		translate(defaultText, localizeText) {
			return Vue.prototype.$filters.localizeText(
				defaultText,
				localizeText,
				this
			);
		},
		handleNo() {
			this.$router.push({ path: '/' });
		}
	}
};
</script>
