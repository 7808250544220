import HomeView from '../views/HomeView';
import LoginView from '../views/LoginView';
import LogoutView from '../views/LogoutView';
import ThemeEditorView from '../views/ThemeEditorView.vue';
import StandardBarcodeInput from '../views/functions/StandardBarCodeInput';

export default [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: { requiresAuth: true }
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView
	},
	{
		path: '/logout',
		name: 'logout',
		component: LogoutView,
		meta: { requiresAuth: true }
	},
	{
		path: '/standardBarcodeInput/:functionName/:step',
		name: 'standardBarcodeInput',
		component: StandardBarcodeInput,
		meta: { requiresAuth: true }
	},
	{
		path: '/themeEditor',
		name: 'themeEditor',
		component: ThemeEditorView,
		meta: { requiresAuth: true }
	}
];
