//Pina auth store converted from vuex
import {
	HttpForNonAuthRequests,
	HttpForAuthRequests
} from '@/services/http-command';
import { CreateUserTheme, ApplyUserTheme } from '@/services/createUserTheme';
import { defineStore } from 'pinia';

const defaultLanguage = 'English';
const defaultIsoLanguage = 'en';

export const useUserStore = defineStore('UserStore', {
	state: () => {
		return {
			language: defaultLanguage,
			userSetLanguage: defaultIsoLanguage,
			currentSite: null,
			sites: [],
			currentOperatorProfile: null,
			scannerOptions: null,
			scannerActions: null,
			scannerGroupedOptions: null,
			userSitesList: [],
			sessionProfile: null,
			showWorkflowNestedMenus: false,
			operatorTheme: null
		};
	},
	persist: true,
	getters: {
		getLanguage() {
			return this.language;
		},
		getUserSetLanguage() {
			return this.userSetLanguage;
		},
		getSites() {
			return this.sites;
		},
		getActiveSite() {
			return this.currentSite;
		},
		getCurrentOperatorProfile() {
			return this.currentOperatorProfile;
		},
		getAllowedScannerFunctions() {
			return this.scannerOptions;
		},
		getScannerGroupedOptions() {
			return this.scannerGroupedOptions;
		},
		getUserSitesList() {
			return this.userSitesList;
		},
		getAllScannerActions() {
			return this.scannerActions;
		},
		getShowWorkflowNestedMenus() {
			return this.showWorkflowNestedMenus;
		},
		getUserSiteDescription() {
			var currSite = this.userSitesList.find(
				(x) => x.SiteID === this.currentSite
			);

			if (!currSite) {
				return '';
			}

			return currSite.SiteName;
		},
		getUserFullName() {
			if (!this.sessionProfile) {
				return '';
			}

			if (!this.sessionProfile.Operator) {
				return '';
			}

			return `${this.sessionProfile.Operator?.FirstName} ${this.sessionProfile.Operator.LastName}`;
		},
		getUsersEmail() {
			if (!this.sessionProfile) {
				return '';
			}

			if (!this.sessionProfile.Operator) {
				return '';
			}

			return this.sessionProfile.Operator.Email;
		},
		getUserInitials() {
			if (!this.sessionProfile) {
				return '';
			}

			if (!this.sessionProfile.Operator) {
				return '';
			}

			var firstInitial = this.sessionProfile.Operator.FirstName.substring(
				0,
				1
			).toUpperCase();
			var secondInitial = this.sessionProfile.Operator.LastName.substring(
				0,
				1
			).toUpperCase();
			return `${firstInitial}${secondInitial}`;
		},
		getOperatorTheme() {
			return this.operatorTheme;
		}
	},
	actions: {
		setUserProfile(userSettings) {
			var currentUser = JSON.parse(userSettings.data.CurrentUser);
			var scannerRoles = JSON.parse(userSettings.data.ScannerRoles);
			var scannerActions = JSON.parse(userSettings.data.ScannerActions);
			var scannerGroupedFunctions = JSON.parse(
				userSettings.data.ScannerGroupedActions
			);

			this.language = userSettings.data.LanguageId;
			this.currentSite = userSettings.site;
			this.currentOperatorProfile = currentUser.OperatorProfile;
			this.scannerOptions = scannerRoles;
			this.scannerActions = scannerActions;
			this.scannerGroupedOptions = scannerGroupedFunctions;
			this.userSitesList = currentUser.OperatorSites;
			this.sessionProfile = currentUser;
		},
		getUserSites() {
			return new Promise((resolve, reject) => {
				HttpForNonAuthRequests()
					.get('/api/whssite/getallwhssite')
					.then((res) => {
						if (res.data != null) {
							var activeSites = res.data.filter(
								(a) => a.Active === 1
							);
							var sitesObject = activeSites.map(function (obj) {
								return { key: obj.SiteID };
							});
							this.sites = sitesObject;
						}
						resolve();
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
		fetchOperatorTheme(operatorId, themeType) {
			return new Promise((resolve, reject) => {
				HttpForAuthRequests()
					.get(`/api/theme/${operatorId}/${themeType}/raptor`)
					.then((res) => {
						if (res.data != null) {
							this.operatorTheme = res.data;
							CreateUserTheme(res.data);
						}
						resolve();
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
		updateOperatorTheme(themePayload, operatorId, vuetifyInstance) {
			return new Promise((resolve, reject) => {
				HttpForAuthRequests()
					.put(`api/theme/${operatorId}`, themePayload)
					.then((res) => {
						if (res.data != null) {
							CreateUserTheme(themePayload);
							ApplyUserTheme(vuetifyInstance);
						}
						resolve();
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
		updateUserActivity(currentActivity) {
			return new Promise((resolve, reject) => {
				var payload = {
					RecordID: -1,
					ActivityID: currentActivity,
					LoggedInFlag: true
				};
				HttpForAuthRequests()
					.post('/mobileapi/operatoractivity/createactivity', payload)
					.then(() => {
						resolve();
					})
					.catch((e) => {
						reject(e);
					});
			});
		},
		updateShowWorkFlowMenu(value) {
			this.showWorkflowNestedMenus = value;
		},
		removeUserPayloads() {
			this.currentOperatorProfile = null;
			this.scannerOptions = null;
			this.scannerActions = null;
			this.sessionProfile = null;
			this.scannerGroupedOptions = null;
		},
		setUserLocal(value, savantLanguage) {
			this.userSetLanguage = value;
			this.language = savantLanguage;
		}
	}
});
