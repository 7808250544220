import localize from './localize';

export default {
	filters: {
		localize
	},
	install: function (Vue) {
		const allFilters = this.filters;
		Object.keys(allFilters).forEach(function (filterCollection) {
			Object.keys(allFilters[filterCollection]).forEach(
				function (filter) {
					Vue.filter(filter, allFilters[filterCollection][filter]);
				}
			);
		});
	}
};
