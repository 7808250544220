import Vue from 'vue';
import { useAuthStore } from '@/stores/authStore';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(async (to, from, next) => {
	const store = useAuthStore();

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in if not redirect to the login page
		if (!store.isAuthenticated) {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
