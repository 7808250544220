<template>
	<v-row align="center" justify="center">
		<v-col
			v-for="workFlowItem in getWorkflowItemsForHeader()"
			:key="workFlowItem.id"
			xs="12"
			sm="5"
			md="3"
			lg="2"
			class="custom-col"
		>
			<v-btn
				class="custom-button"
				style="height: 105px"
				@click="
					openWorkflow(
						workFlowItem.ViewName,
						workFlowItem.ActivityName
					)
				"
			>
				<v-row align="center" justify="center">
					<div class="icon-text-container">
						<v-icon x-large :color="workFlowItem.MenuIconColour">
							{{ workFlowItem.MenuIcon }}
						</v-icon>
						<div class="custom-text">
							{{ translate(workFlowItem.WorkflowName) }}
						</div>
					</div>
				</v-row>
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/userStore';
export default {
	name: 'ScannerWorkFlowButtons',
	props: {
		headerid: {
			type: Array,
			required: true
		}
	},
	methods: {
		...mapState(useUserStore, ['getAllowedScannerFunctions']),
		...mapActions(useUserStore, ['updateUserActivity']),
		translate(key) {
			return this.$t(key);
		},
		getWorkflowItemsForHeader() {
			//ok this is going to pull in a parent id and return a list of workflows with that id to display
			var scannerWorkflows = this.getAllowedScannerFunctions();

			var itemMatchingHeaders = [];
			this.headerid.map(function (value) {
				var itemsForHeader = scannerWorkflows.filter(
					(a) => a.SequenceNumber === 1 && a.DisplayParentId === value
				);

				itemMatchingHeaders.push(...itemsForHeader);
			});

			return itemMatchingHeaders;
		},
		openWorkflow(viewName, activityName) {
			// pull the activity and post to the endpoint
			if (activityName) {
				this.updateUserActivity(activityName);
			}

			// now go to the route
			this.$router.push({ path: viewName });
		}
	}
};
</script>

<style>
.custom-col {
	margin-right: 5px;
	margin-top: 4px;
}

.custom-button {
	width: 160px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 20px !important;
	margin: 0px;
	padding: 0;
	text-align: center;
}

.theme--light.custom-button {
	border: 4px solid black;
}
.theme--dark.custom-button {
	border: 4px solid white;
}

.icon-text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.custom-text {
	font-size: 14px;
	margin-top: 3px;
	white-space: normal;
	word-break: break-word;
	text-align: center;
}
</style>
