<template>
	<v-app>
		<vue-progress-bar />
		<v-idle :loop="true" :duration="logoutIdleTime" @idle="onidle" />
		<nav-bar />
		<v-main class="background">
			<router-view />
		</v-main>
		<v-footer class="background">
			<v-col class="text-center" cols="12" style="font-size: 12px">
				<strong>Copyright</strong> Savant WMS - Web &copy; 2014 -
				{{ new Date().getFullYear() }}
			</v-col>
		</v-footer>
	</v-app>
</template>

<script>
import NavBar from '@/components/shared/navbar.vue';
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/authStore';

export default {
	name: 'DefaultLayout',
	components: {
		NavBar
	},
	computed: {
		logoutIdleTime() {
			return parseInt(process.env.VUE_APP_LOGOUTIDLESECONDS);
		}
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			if (newVal != oldVal) {
				if (this.$refs.form != undefined) {
					this.$refs.form.resetValidation();
				}
				localStorage.setItem('custom_i18n_locale', newVal);
			}
		}
	},
	beforeMount() {
		const locale = localStorage.getItem('custom_i18n_locale');
		if (locale) {
			this.$i18n.locale = locale;
		}
	},
	methods: {
		...mapState(useAuthStore, ['isAuthenticated']),
		...mapActions(useAuthStore, ['logout']),
		onidle() {
			if (this.isAuthenticated()) {
				this.logout().then(() => {
					this.$router.push({ path: '/login' });
				});
			}
		}
	}
};
</script>
