import { useScannerStore } from '@/stores/scannerFunctionStore';

/**
 * Gets value of a nested property value by periods in the key string
 * @param {number} externalEndpointId External endpoint id.
 * @param {string} rawBody Body of the http message to send to the upstream API.
 * @param {string} formInput The data of the form we used to submit this request.
 * @param {string} overrideEndpointUrl A url we have replaced values in to overide the one nomally pulled from the config table
 * @param {string} extraFilterConditions Config of any extra fileds we want to filter on
 */
export const CallExternalApi = function (
	externalEndpointId,
	rawBody,
	formInput,
	overrideEndpointUrl,
	extraFilterConditions
) {
	return new Promise((resolve, reject) => {
		const scannerStore = useScannerStore();

		var requestBody = {
			ExternalEndpointId: externalEndpointId,
			ExternalEndpointRawBody: rawBody,
			OverrideEndpointUrl: overrideEndpointUrl,
			ExtraFilterConditions: extraFilterConditions
		};

		var fullRequest = {
			requestBody: requestBody,
			formInput: formInput
		};

		scannerStore
			.runExternalApiRequest(fullRequest)
			.then(() => {
				resolve();
			})
			.catch((e) => {
				reject(e);
			});
	});
};
