<template>
	<v-row no-gutters>
		<v-col cols="sm">
			<v-card class="pt-2 pl-2 labelDisplayBox" outlined tile>
				<v-autocomplete
					:id="inputBox.Name"
					autofocus
					:label="labelText"
					:items="autoCompleteItems"
					item-text="value"
					item-value="key"
					data-autoCompleteBox
					@change="onInput"
					@onfocus="blur()"
				/>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from 'pinia';
import { useScannerStore } from '@/stores/scannerFunctionStore';
export default {
	name: 'AutoCompleteInput',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	computed: {
		labelText() {
			return this.$options.filters.localizeText(
				this.inputBox.Text,
				this.inputBox.LocalizedText,
				this
			);
		},
		autoCompleteItems() {
			if (this.inputBox.AutoCompleteSource === 'lastApiResponse') {
				return this.getAutoCompleteItemsFromApiResponse(this.inputBox);
			}
			return [];
		}
	},
	methods: {
		...mapState(useScannerStore, [
			'getLastApiResponse',
			'getAllApiResponses'
		]),
		onInput(str) {
			this.$emit('inputUpdate', this.inputBox.Name + ':' + str);
		},
		getAutoCompleteItemsFromApiResponse(vmInputBox) {
			var apiResponse = this.getLastApiResponse();
			var items = [];
			var autoCompleteTextItems = vmInputBox.AutoCompleteText.split(':');

			if (Array.isArray(apiResponse)) {
				apiResponse.forEach(function (item) {
					var keyItem = item[vmInputBox.AutoCompleteKey];

					if (keyItem) {
						let itemTextForBox = [];

						autoCompleteTextItems.forEach(function (textItem) {
							itemTextForBox.push(item[textItem]);
						});

						items.push({
							key: keyItem,
							value: itemTextForBox.join(', ').toUpperCase()
						});
					}
				});
			}

			if (items.length === 0) {
				//lets try our all API responses
				var allApiResponse = this.getAllApiResponses();

				allApiResponse.forEach(function (item) {
					//object first
					if (
						typeof item === 'object' &&
						item !== null &&
						!Array.isArray(item)
					) {
						//does our key exist in here?
						var keyItem = item[vmInputBox.AutoCompleteKey];

						if (keyItem) {
							//then we can get the value
							let itemTextForBox = [];
							autoCompleteTextItems.forEach(function (textItem) {
								itemTextForBox.push(item[textItem]);
							});
							items.push({
								key: keyItem,
								value: itemTextForBox.join(', ')
							});
						}
					}

					//now arrays
					if (Array.isArray(item)) {
						item.forEach(function (item) {
							var keyItem = item[vmInputBox.AutoCompleteKey];

							if (keyItem) {
								let itemTextForBox = [];

								autoCompleteTextItems.forEach(
									function (textItem) {
										itemTextForBox.push(item[textItem]);
									}
								);
								items.push({
									key: keyItem,
									value: itemTextForBox.join(', ')
								});
							}
						});
					}
				});
			}
			return items;
		}
	}
};
</script>
