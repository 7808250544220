const dateTimeFormats = {
	en: {
		short: { year: 'numeric', month: '2-digit', day: '2-digit' }
	},
	es: {
		short: { year: 'numeric', month: '2-digit', day: '2-digit' }
	},
	fr: {
		short: { year: 'numeric', month: '2-digit', day: '2-digit' }
	}
};
export default dateTimeFormats;
