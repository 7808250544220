export default {
	data() {
		return {
			refreshing: false,
			registration: null,
			updateExists: false
		};
	},

	created() {
		// Check if we are in a test environment
		if (!process.env.VUE_APP_TESTING_FLAG) {
			// Listen for our custom event from the SW registration
			document.addEventListener('swUpdated', this.updateAvailable, {
				once: true
			});

			// Prevent multiple refreshes
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		}
	},

	methods: {
		// Store the SW registration so we can send it a message
		updateAvailable(event) {
			this.registration = event.detail;
			this.updateExists = true;
			this.refreshApp();
		},

		refreshApp() {
			this.updateExists = false;
			// Make sure we only send a 'skip waiting' message if the SW is waiting
			if (!this.registration || !this.registration.waiting) return;
			// send message to SW to skip the waiting and activate the new SW
			this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}
	}
};
