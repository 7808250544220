<template>
	<v-container class="px-1 py-0">
		<v-expansion-panels
			v-if="getShowWorkflowNestedMenus()"
			class="rounded-lg"
			accordion
			focusable
		>
			<v-expansion-panel
				v-for="(mainHeader, i) in getPanelHeaders"
				:key="i"
			>
				<v-expansion-panel-header>
					<div>
						<v-icon
							v-if="mainHeader.MenuIcon"
							class="pr-2"
							:color="mainHeader.MenuIconColour"
						>
							{{ mainHeader.MenuIcon }}
						</v-icon>
						<span>
							{{ translate(mainHeader.LocalizationKey) }}
						</span>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<!-- Nested Panels Level 1 -->
					<v-expansion-panels class="condensed">
						<v-expansion-panel
							v-for="(subHeader1, ii) in getNestedHeaders(
								mainHeader.GroupId
							)"
							:key="ii"
						>
							<v-expansion-panel-header>
								{{ translate(subHeader1.LocalizationKey) }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<!-- Nested Panels Level 2 -->
								<v-expansion-panels>
									<v-expansion-panel
										v-for="(
											subHeader2, iii
										) in getNestedHeaders(
											subHeader1.GroupId
										)"
										:key="iii"
									>
										<v-expansion-panel-header>
											{{
												translate(
													subHeader2.LocalizationKey
												)
											}}
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<!-- Sub header2 actions -->
											<workflowButtons-list
												ref="workflowButtonsSub2-list"
												:headerid="[subHeader2.GroupId]"
											/>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>

								<!-- Sub header1 actions -->
								<workflowButtons-list
									ref="workflowButtonsSub1-list"
									:headerid="[subHeader1.GroupId]"
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<!-- Main header actions -->
					<workflowButtons-list
						ref="workflowButtonsMainHeader-list"
						:headerid="[mainHeader.GroupId]"
					/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<div v-else>
			<!-- When suppressing menus -->
			<workflowButtons-list
				ref="workflowButtonsNoHeaders-list"
				:headerid="getAllHeaderIds()"
			/>
		</div>
	</v-container>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import WorkflowScannerButtons from './WorkFlowCards.vue';
export default {
	name: 'ScanningOptions',
	components: {
		'workflowButtons-list': WorkflowScannerButtons
	},
	computed: {
		getPanelHeaders() {
			//top level groups, ie nothing with a parent
			var groups = this.getScannerGroupedOptions();
			return groups.filter((a) => a.ParentId === 0);
		}
	},
	methods: {
		...mapState(useUserStore, [
			'getScannerGroupedOptions',
			'getShowWorkflowNestedMenus'
		]),
		getNestedHeaders(headerParentId) {
			var groups = this.getScannerGroupedOptions();
			return groups.filter((a) => a.ParentId === headerParentId);
		},
		getAllHeaderIds() {
			var groups = this.getScannerGroupedOptions();
			return groups.map((a) => a.GroupId);
		},
		translate(key) {
			return this.$t(key);
		}
	}
};
</script>

<style>
.flexcol .v-btn__content {
	flex-direction: column;
}
.v-expansion-panel-content__wrap {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.rounded-panel {
	border-radius: 50px;
}
.v-expansion-panels.condensed .v-expansion-panel-header {
	padding-top: 5px;
	padding-bottom: 5px;
	min-height: auto;
}
.v-expansion-panels.condensed
	.v-expansion-panel--active
	.v-expansion-panel-header {
	padding-top: 8px;
	padding-bottom: 8px;
}
.v-expansion-panels.condensed .v-expansion-panel--active:not(:first-child),
.v-expansion-panels.condensed .v-expansion-panel--active + .v-expansion-panel {
	margin-top: 4px;
}
</style>
