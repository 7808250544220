<template>
	<v-container>
		<v-card color="background" flat class="mx-auto">
			<v-row class="text-center">
				<v-col cols="12">
					<h3>{{ translate('Capture Image', 'comp_TakePhoto') }}</h3>
				</v-col>
			</v-row>

			<div v-show="isCameraOpen && isLoading" class="camera-loading">
				{{ translate('Loading...', 'common_loading') }}
			</div>

			<div v-if="isCameraOpen" v-show="!isLoading">
				<video
					v-show="!isPhotoTaken"
					ref="camera"
					:width="300"
					:height="337.5"
					autoplay
				/>
				<canvas
					v-show="isPhotoTaken"
					id="photoTaken"
					ref="canvas"
					:width="300"
					:height="337.5"
				/>
			</div>

			<v-card-actions class="justify-center">
				<div v-if="isCameraOpen && !isLoading" class="mr-1">
					<v-btn color="primary" data-takePhoto @click="takePhoto()">
						<v-icon
							>{{ !isPhotoTaken ? 'mdi-camera' : 'mdi-redo' }}
						</v-icon>
					</v-btn>
				</div>

				<div v-if="isPhotoTaken && isCameraOpen" class="mr-1">
					<v-btn
						id="downloadPhoto"
						download="my-photo.jpg"
						color="primary"
						data-savePhoto
						@click="saveImageData()"
					>
						<v-icon>mdi-content-save</v-icon>
					</v-btn>
				</div>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useScannerStore } from '@/stores/scannerFunctionStore';
import { useAuthStore } from '@/stores/authStore';
import Vue from 'vue';

export default {
	name: 'ImageCaptureComponent',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isCameraOpen: false,
			isPhotoTaken: false,
			isShotPhoto: false,
			isLoading: false,
			link: '#'
		};
	},
	computed: {
		...mapState(useScannerStore, ['getShowModalValue'])
	},
	watch: {
		getShowModalValue(newValue) {
			if (newValue) {
				this.toggleCamera(true);
			} else {
				this.toggleCamera(false);
			}
		}
	},
	mounted() {
		//load the camera on first mount
		this.toggleCamera(true);
	},
	methods: {
		...mapActions(useScannerStore, ['runApiPostRequest']),
		...mapState(useScannerStore, ['getAllApiResponses']),
		...mapState(useAuthStore, ['getUserId']),
		toggleCamera(showCamera) {
			if (!showCamera) {
				this.isCameraOpen = false;
				this.isPhotoTaken = false;
				this.isShotPhoto = false;
				this.stopCameraStream();
			} else {
				this.isCameraOpen = true;
				this.createCameraElement();
			}
		},
		createCameraElement() {
			this.isLoading = true;

			const constraints = (window.constraints = {
				audio: false,
				video: true
			});

			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch((error) => {
					this.isLoading = false;
					console.error(error);
				});
		},

		stopCameraStream() {
			if (typeof this.$refs.camera.srcObject.getTracks === 'function') {
				let tracks = this.$refs.camera.srcObject.getTracks();

				if (Array.isArray(tracks)) {
					tracks.forEach((track) => {
						track.stop();
					});
				}
			}
		},

		//take current state from the camera and dump it onto the canvas
		takePhoto() {
			if (!this.isPhotoTaken) {
				this.isShotPhoto = true;
				const FLASH_TIMEOUT = 50;
				setTimeout(() => {
					this.isShotPhoto = false;
				}, FLASH_TIMEOUT);
			}

			this.isPhotoTaken = !this.isPhotoTaken;

			const context = this.$refs.canvas.getContext('2d');
			context.drawImage(this.$refs.camera, 0, 0, 300, 337.5);
		},
		translate(defaultText, localizeText) {
			return Vue.prototype.$filters.localizeText(
				defaultText,
				localizeText,
				this
			);
		},

		//download/save the image
		saveImageData() {
			const cameraImage = document
				.getElementById('photoTaken')
				?.toDataURL('image/jpeg');

			var payload = {
				actionId: this.createSwapTokenForValue(this.inputBox.Id),
				actionName: this.inputBox.Action,
				imageData: cameraImage,
				operatorId: this.swapStandardTokens(this.inputBox.OperatorId)
			};

			this.runApiPostRequest({
				url: '/mobileapi/shared/saveActionImage',
				body: payload,
				formInput: null
			})
				.then(() => {
					this.$toast.success('Saved image', {
						timeout: parseInt(process.env.VUE_APP_TOASTTIMEOUT)
					});
				})
				.finally(() => {
					this.stopCameraStream();
					this.$emit('closeModel', true);
				});
		},
		createSwapTokenForValue(payloadToSwap) {
			const allApiResponses = this.getAllApiResponses();

			if (!Array.isArray(allApiResponses)) {
				return payloadToSwap;
			}

			allApiResponses.forEach(function (apiResponse) {
				if (typeof apiResponse === 'object' && apiResponse !== null) {
					Object.keys(apiResponse).forEach(function (key) {
						var tokenToFind = '#' + key + '#';
						if (tokenToFind == payloadToSwap) {
							payloadToSwap = payloadToSwap.replace(
								tokenToFind,
								apiResponse[key]
							);
						}
					});
				}
			});

			return payloadToSwap;
		},
		swapStandardTokens(inputString) {
			inputString = inputString.replace('#userId#', this.getUserId());
			return inputString;
		}
	}
};
</script>

<style scoped>
video {
	object-fit: inherit;
}
</style>
