<template>
	<div>
		<LoadingScreen v-if="showLoadingScreen" @doneLoading="doneLoading" />
		<component :is="layout" v-else>
			<router-view />
		</component>
	</div>
</template>
<script>
import LoadingScreen from './components/LoadingScreen.vue';
import update from './mixins/appUpdateMixin';
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/authStore';
const defaultLayoutName = 'default';

export default {
	components: {
		LoadingScreen
	},
	mixins: [update],
	data: () => ({
		showLoading: true
	}),
	computed: {
		...mapState(useAuthStore, ['getShowSplashScreen']),
		layout() {
			return (this.$route.meta.layout || defaultLayoutName) + '-layout';
		},
		showLoadingScreen() {
			//only show if its the first time
			return this.showLoading & this.getShowSplashScreen;
		}
	},
	methods: {
		...mapActions(useAuthStore, ['setShowSplashScreen']),
		doneLoading() {
			this.showLoading = false;
			this.setShowSplashScreen(false);
		}
	}
};
</script>
