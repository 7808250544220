<template>
	<v-btn
		:color="actionSettings.component.colour"
		:disabled="isButtonDisabled"
		small
		data-actionButton
		@click="handleButtonClick()"
	>
		<v-icon v-if="actionSettings.component.icon" left>
			{{ actionSettings.component.icon }}
		</v-icon>
		{{ buttonText }}
	</v-btn>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
export default {
	name: 'ActionButton',
	props: {
		actionSettings: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState(useUserStore, ['showSplashScreen']),
		buttonText() {
			return this.$options.filters.localizeText(
				this.actionSettings.component.text,
				this.actionSettings.component.localizedText,
				this
			);
		},
		isButtonDisabled() {
			return this.getApiRequestInProgress;
		}
	},
	methods: {
		handleButtonClick() {
			this.$emit(
				'actionTrigger',
				this.actionSettings.actionId,
				this.actionSettings.id
			);
		}
	}
};
</script>
