<template>
	<v-row no-gutters>
		<v-col cols="sm">
			<v-card
				class="pt-2 pl-2 labelDisplayBox"
				outlined
				tile
				color="background"
			>
				<v-text-field
					:id="inputBox.Name"
					ref="inputField"
					v-model="dataInput"
					:inputmode="inputMode"
					autofocus
					data-TextBox
					:label="labelText"
					@input="onInput"
					@keyup="uppercase"
				/>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import Vue from 'vue';
export default {
	name: 'TextBoxInput',
	props: {
		inputBox: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		dataInput: '',
		hideKeyboard: true
	}),
	computed: {
		labelText() {
			return Vue.prototype.$filters.localizeText(
				this.inputBox.Text,
				this.inputBox.LocalizedText,
				this
			);
		},
		inputMode() {
			if (this.hideKeyboard) {
				return 'none';
			} else {
				if (this.inputBox?.type === 'number') {
					return 'numeric';
				}
				return 'text';
			}
		}
	},
	watch: {
		$props: {
			handler(newValue, oldValue) {
				//first time we've seen the item, so just disable the keyboard
				if (!oldValue?.inputBox.Name) {
					setTimeout(() => (this.hideKeyboard = false), 100);
					return;
				}

				//must be a new textbox, so hide the keyboard, then show it again
				this.hideKeyboard = true;
				this.$nextTick(() => {
					setTimeout(() => (this.hideKeyboard = false), 500);
				});
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		onInput(str) {
			if (str) {
				var outputVal = str.toUpperCase();
				this.$emit('inputUpdate', this.inputBox.Name + ':' + outputVal);
			}
		},
		uppercase() {
			if (this.dataInput) {
				this.dataInput = this.dataInput.toUpperCase();
			}
		}
	}
};
</script>
