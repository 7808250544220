<template>
	<div v-if="hasGotActionItems">
		<v-card color="background" flat class="mx-auto">
			<v-card-actions>
				<div
					v-for="p in actionItems.stepActions"
					:key="p.id"
					class="mr-1"
				>
					<component
						:is="getComponentName(p)"
						:action-settings="p"
						@actionTrigger="onActionTrigger"
					/>
				</div>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import actionButton from './ActionComponentTypes/ActionButton.vue';
import { mapState, mapActions } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { useScannerStore } from '@/stores/scannerFunctionStore';

export default {
	name: 'ActionComponentList',
	components: {
		'component-button': actionButton
	},
	props: {
		actionItems: {
			type: Object,
			default: () => {},
			required: false
		}
	},
	computed: {
		hasGotActionItems() {
			if (this.actionItems?.stepActions) {
				return true;
			}

			return false;
		}
	},
	methods: {
		...mapState(useUserStore, ['getAllScannerActions']),
		...mapActions(useScannerStore, [
			'goBackStep',
			'goBackToStart',
			'removeItemFromIterationInput',
			'updateModalState'
		]),
		getComponentName(q) {
			return 'component-' + q.component.type;
		},
		onActionTrigger(actionId, stepActionButtonId) {
			var availableActions = this.getAllScannerActions();
			var actionToDispatch = availableActions.find(
				(o) => o.id === actionId
			);

			if (!actionToDispatch) {
				console.error('No action found!', actionId);
				return;
			}

			//if we are a triggerFormSubmit type then instead of dispatching we need to just emit the submit event up
			if (actionToDispatch.triggerFormSubmit) {
				this.$emit(
					'actionWorkFlowSubmitTrigger',
					actionId,
					stepActionButtonId
				);
				return;
			}

			//ok now we are using pinia this is more clunky, but we can still do it
			var actionPayload = this.createActionPayload(
				actionToDispatch.payload
			);
			if (actionToDispatch.storeName === 'scannerFunctionsStore') {
				if (actionToDispatch.storeAction === 'goBackStep') {
					this.goBackStep();
				}

				if (actionToDispatch.storeAction === 'goBackToStart') {
					this.goBackToStart();
				}

				if (
					actionToDispatch.storeAction ===
					'removeItemFromIterationInput'
				) {
					this.removeItemFromIterationInput(actionPayload);
				}

				if (actionToDispatch.storeAction === 'showModal') {
					this.updateModalState(actionPayload);
				}
			}
		},
		createActionPayload(payloadSettings) {
			return payloadSettings;
		}
	}
};
</script>
